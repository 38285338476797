import { IRootState } from '@football/types/app';
import { colorsLight, colorsDark } from '@football/styles';
import { CacheKey } from './types';
import { API_URL } from '@football/config/constants';

export const getUrl = () => {
  // if (process.env.NODE_ENV === 'development') {
  //   return 'http://localhost:3000/dev';
  // } else if (process.env.NODE_ENV === 'test') {
  //   return 'http://localhost';
  // }
  return API_URL;
};

export const getId = (state: IRootState) => state.app.id;

export const getUrlScheme = (state: IRootState) => state.app.urlScheme;

export const getMessage = (state: IRootState) => state.app.message;

export const getFeatures = (state: IRootState) => state.app.config.features;

export const getSum = (state: IRootState) => state.app.config.sum;

export const getBackgroundMode = (state: IRootState) =>
  state.app.backgroundMode;

export const getFilter = (state: IRootState) => state.app.config.filter;

export const getModal = (state: IRootState) => state.app.modal;

export const getColors = (state: IRootState) =>
  state.app.colorScheme === 'dark' ? colorsDark : colorsLight;

export const getIsDark = (state: IRootState) =>
  state.app.colorScheme === 'dark';

export const getDisableBackgroundMode = (state: IRootState) =>
  state.app.disableBackgroundMode;

export const getCacheKey = (state: IRootState, key?: CacheKey) =>
  state.app.cache[key || 'app'];

export const getRoute = (state: IRootState) => state.app.route;

export const getStore = (state: IRootState) => state.app.store;

export const getAds = (state: IRootState) => state.app.config.ads;
