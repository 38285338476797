import React, { useState, memo } from 'react';
import { FlatList } from 'react-native';

import {
  Header,
  Wrapper,
  Paragraph,
  Spacing,
  ListItem,
  Image,
} from '../components';
import {
  types as scoreTypes,
  actions as scoreActions,
} from '@football/modules/score';
import { actions as appActions } from '@football/modules/app';
import { useAppDispatch, useAppSelector } from '@football/app-utils';
import { selectors as userSelectors } from '@football/modules/user';
import { i18n } from '@football/localization';

interface IProps {
  data: scoreTypes.IScore[];
  filter?: scoreTypes.IFilter;
}

interface IItemProps {
  item: scoreTypes.IScore;
  index: number;
  filter?: scoreTypes.IFilter;
}

const Item = memo(({ item, index, filter }: IItemProps) => {
  return (
    <ListItem padded>
      <Wrapper row flexible centerItems>
        <Wrapper flexible row centerItems>
          <Wrapper row centerItems>
            <Spacing mr={item.country ? 10 : 15}>
              <Header light>{index + 1}</Header>
            </Spacing>
            {item.country && (
              <Spacing mr={10}>
                <Image
                  size={filter !== 'person' && filter ? '25px' : '30px'}
                  picture={item.picture}
                  label={item.country}
                />
              </Spacing>
            )}
          </Wrapper>
          <Wrapper startItems flexible>
            <Spacing mb={item.groupName ? 5 : 0}>
              <Header subtitle>{item.name}</Header>
            </Spacing>
            {item.groupName && (
              <Paragraph truncate>
                {filter !== 'person' && filter
                  ? item.groupName + ' ' + i18n.translate('group.players.title')
                  : item.groupName}
              </Paragraph>
            )}
          </Wrapper>
        </Wrapper>
        <Spacing ml={10}>
          <Paragraph bold>{item.points}</Paragraph>
        </Spacing>
      </Wrapper>
    </ListItem>
  );
});

const ListScores = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [refresh, setRefresh] = useState<boolean>(false);
  const loggedIn = useAppSelector(userSelectors.getLoggedIn);

  const renderItem = ({
    item,
    index,
  }: {
    item: scoreTypes.IScore;
    index: number;
  }) => <Item key={item.key} filter={props.filter} index={index} item={item} />;

  const refreshScores = async () => {
    setRefresh(true);
    dispatch(appActions.setCacheState({ key: 'score' }));
    if (loggedIn) {
      await dispatch(scoreActions.refreshScores(false));
    } else {
      await dispatch(scoreActions.refreshPublicScores({ showLoader: false }));
    }
    setRefresh(false);
  };

  return (
    <FlatList
      accessibilityRole="list"
      accessibilityLabel="List Scores"
      refreshing={refresh}
      onRefresh={refreshScores}
      data={props.data}
      renderItem={renderItem}
    />
  );
};

export default ListScores;
