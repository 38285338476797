export const STANDINGS = {
  1: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
  2: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
  3: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
  4: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
  5: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
  6: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
  7: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
  8: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
  9: {
    FIRST_PLACE: 760,
    SECOND_PLACE: 770,
    THIRD_PLACE: 0,
    TOP_SCORERS: [144393, 7459, 62511, 136646, 8004, 23132],
  },
  11: {
    FIRST_PLACE: 0,
    SECOND_PLACE: 0,
    THIRD_PLACE: 0,
    TOP_SCORERS: [0],
  },
};

export const SET_TIME_OUT = 20000;
export const SET_INTERVAL = 30000;

export const POINTS = {
  FIRST_PLACE: 10,
  SECOND_PLACE: 10,
  THIRD_PLACE: 10,
  TOP_SCORERS: 10,
};

export const API_URL = process.env.REACT_APP_WEB
  ? 'https://football.srvc.io/api'
  : process.env.NODE_ENV === 'test'
    ? 'http://localhost'
    : 'https://football.api.services.srvc.io';

export const CDN_URL = 'https://football.srvc.io/images';
