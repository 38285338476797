import React, { useState } from 'react';
import { TextInputProps, I18nManager } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import styled, { css } from 'styled-components/native';
import { useTheme } from 'styled-components/native';

import { Button } from './';
import { IColors } from '@football/styles/types';
import {
  actions as errorActions,
  selectors as errorSelectors,
} from '@football/modules/error';
import { useAppDispatch, useAppSelector } from '@football/app-utils';

const getAccessibilityLabel = ({
  testID,
  placeholder,
  value,
}: {
  testID: string;
  placeholder?: string;
  value?: string;
}) => {
  if (value) {
    return value;
  }
  if (placeholder) {
    return placeholder;
  }
  return testID;
};

interface IProps extends TextInputProps {
  width?: number;
  textAlign?: 'left' | 'center' | 'right';
  testID: string;
  clear?: boolean;
  center?: boolean;
  focus?: boolean;
  onClearAction?: () => void;
  colors?: IColors;
}

const center = css`
  text-align: center;
`;

const StyledInput = styled.TextInput<IProps>`
  height: 50px;
  color: ${props => props.colors?.gray[700]};
  padding-left: 10px;
  padding-right: 10px;
  border-width: 1px;
  border-radius: 5px;
  font-family: Raleway-Medium;
  background-color: ${props => props.colors?.gray[100]};
  border-color: ${props =>
    props.focus ? props.colors?.gray[400] : props.colors?.gray[300]};
  ${props => props.center && center};
  ${props =>
    I18nManager.isRTL && !props.textAlign ? 'text-align:right' : null};
  ${props =>
    I18nManager.isRTL && !props.textAlign
      ? 'writing-direction:rtl'
      : 'writing-direction:ltr'};
`;

const WrapperInput = styled.View`
  position: relative;
`;

const WrapperClear = styled.View`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 50px;
  justify-content: center;
`;

export default (props: IProps) => {
  const theme = useTheme();
  const { colors } = theme;
  const error = useAppSelector(errorSelectors.getError);

  const [focus, setFocus] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onFocus = () => {
    setFocus(true);
    if (error.message) {
      dispatch(errorActions.resetErrorState());
    }
  };
  const onBlur = () => {
    setFocus(false);
  };

  return (
    <WrapperInput>
      <StyledInput
        {...props}
        accessible
        accessibilityHint={getAccessibilityLabel({
          testID: props.testID,
          placeholder: props.placeholder,
          value: props.value,
        })}
        accessibilityRole="text"
        placeholderTextColor={colors.gray[700]}
        onFocus={onFocus}
        focus={focus}
        onBlur={onBlur}
        colors={colors}
      />
      {props.clear &&
        props.onClearAction &&
        props.value &&
        props.value.length > 0 && (
          <WrapperClear>
            <Button
              icon
              testID="inputClearAction"
              disableLoader
              onPress={props.onClearAction}>
              <Icon
                accessible={false}
                importantForAccessibility="no"
                accessibilityElementsHidden={true}
                name="close-circle"
                size={25}
                color={colors.gray[700]}
              />
            </Button>
          </WrapperClear>
        )}
    </WrapperInput>
  );
};
