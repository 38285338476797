import { i18n } from '@football/localization';
import { IDeviceInfo, IPlatform } from '@football/types/app';

export class FetchError extends Error {
  public resource;
  public status;
  public type;
  constructor(message: string, resource: string, status: number) {
    super(message);
    this.name = 'Error';
    this.type = 'FetchError';
    this.resource = resource;
    this.status = status;
  }
}

const getToken = (token?: string | null) => {
  if (token) {
    return { 'x-auth-token': token };
  }
};

export const getCacheKey = (cacheKey?: string) => {
  if (cacheKey && !process.env.REACT_APP_WEB) {
    return { 'x-cache-key': cacheKey };
  }
};

export const getHeaders = (
  DeviceInfo: IDeviceInfo,
  Platform: IPlatform,
  cacheKey?: string,
  token?: string | null,
) => {
  return {
    ...getToken(token),
    ...getCacheKey(cacheKey),
    Accept: 'application/json',
    'content-type': 'application/json',
    'x-version': DeviceInfo.getVersion(),
    'x-build-number': DeviceInfo.getBuildNumber(),
    'x-os': Platform.OS,
    'x-unique-id': DeviceInfo.getUniqueIdSync().replaceAll('-', ''),
  };
};

export const fetchHandler = async <T>(
  resource: string,
  options: {
    timeout?: number;
    headers?: { [name: string]: string };
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    body?: string;
  },
): Promise<T> => {
  const {
    body,
    headers,
    method,
    timeout = process.env.API_TIMEOUT
      ? parseInt(process.env.API_TIMEOUT as string, 10)
      : 60000,
  } = options;

  const abortController = new AbortController();
  const id = setTimeout(() => {
    abortController.abort();
  }, timeout);

  try {
    const response = await fetch(resource, {
      body,
      headers,
      method,
      signal: abortController.signal,
    });

    const json = await response.json();

    if (response.ok) {
      clearTimeout(id);
      return json;
    } else {
      throw new FetchError(json.message, resource, response.status);
    }
  } catch (error) {
    const { name, message } = error as Error;
    clearTimeout(id);
    if (name === 'AbortError') {
      throw new FetchError(i18n.translate('error.abort-error'), resource, 408);
    }
    throw new FetchError(message, resource, 500);
  }
};
