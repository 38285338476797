import React, { useState } from 'react';
import auth from '@react-native-firebase/auth';

import { Wrapper, Spacing, Input, Button } from '../components';
import { i18n } from '@football/localization';

interface IProps {
  onCloseModal: () => void;
}

const ResetPassord = (props: IProps) => {
  const [email, setEmail] = useState<string>('');

  const changeEmail = (newEmail: string) => {
    setEmail(newEmail);
  };

  const resetPassord = async () => {
    if (email) {
      await auth().sendPasswordResetEmail(email);
      props.onCloseModal();
    }
  };

  return (
    <Wrapper>
      <Spacing mb={15}>
        <Input
          testID="loginTextInputEmail"
          returnKeyType="next"
          value={email}
          onChangeText={changeEmail}
          keyboardType="email-address"
          autoCapitalize="none"
          autoCorrect={false}
          placeholder={i18n.translate('login.textInputEmail.placeHolder')}
        />
      </Spacing>
      <Button
        testID="loginButtonLogin"
        primary
        validate
        text={i18n.translate('login.buttonResetPassword')}
        onPress={resetPassord}
      />
    </Wrapper>
  );
};

export default ResetPassord;
