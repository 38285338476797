import React from 'react';
import { ActivityIndicator, ImageBackground, StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

const internalStyles = StyleSheet.create({
  image: {
    flex: 1,
    justifyContent: 'center',
  },
});

const Loader = () => {
  const theme = useTheme();
  const { colors } = theme;
  return (
    <ImageBackground
      accessible={false}
      importantForAccessibility="no"
      accessibilityElementsHidden={true}
      resizeMode="cover"
      source={require('../images/splash.png')}
      style={internalStyles.image}>
      <ActivityIndicator
        accessible={false}
        importantForAccessibility="no"
        accessibilityElementsHidden={true}
        size="large"
        color={colors.gray[900]}
      />
    </ImageBackground>
  );
};

export default Loader;
