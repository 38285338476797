import { AnyAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IState, IMatchUser, IMatch } from './types';
import { selectors as appSelectors, actions as appActions } from '../app/';
import { selectors as userSelectors } from '../user';
import { actions as errorActions } from '../error';
import { fetchHandler, getHeaders } from '@football/api';
import { IRootState, IExtraArgument } from '@football/types/app';
import { actions as loaderActions } from '../loader';
import { types as matchTypes } from './';
import { IContractMatch } from '@football/types';

export const initialState: IState = {
  query: '',
  matches: [],
  loader: false,
};

const updateUsers = (users: IMatchUser[], currentUser: IMatchUser) => {
  const userExists = users.find(user => user.id === currentUser.id);
  if (userExists) {
    return users.map(user =>
      user.id === currentUser.id
        ? {
            ...user,
            score: currentUser.score,
          }
        : user,
    );
  }
  return users.concat(currentUser);
};

const shouldClearLoader = (action: AnyAction) => {
  return action.type === 'error/setError/fulfilled';
};

const updateMatches = (
  matches: IMatch[],
  id: number,
  currentUser: IMatchUser,
) => {
  return matches.map(match =>
    match.id === id
      ? {
          ...match,
          users: updateUsers(match.users, currentUser),
        }
      : match,
  );
};

interface SaveMatchResult {
  id: number;
  homeTeam: string;
  awayTeam: string;
  isModal?: boolean;
  navigate?: (route: string) => void;
}

const saveMatchResult = createAsyncThunk(
  'match/saveMatchResult',
  async (
    { id, homeTeam, awayTeam, isModal, navigate }: SaveMatchResult,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      const currentUser = userSelectors.getUser(state);
      if (!currentUser) {
        return;
      }
      await fetchHandler(`${appSelectors.getUrl()}/matches/${id}/result`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            userSelectors.getToken(state),
          ),
        },
        method: 'POST',
        body: JSON.stringify({
          score: `${homeTeam}-${awayTeam}`,
        }),
      });

      dispatch(appActions.setCacheState({ key: 'match' }));
      dispatch(appActions.setModalState({ modal: undefined }));
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      if (isModal) {
        navigate && navigate('TabNavigator');
      }

      return {
        id,
        user: {
          mid: id,
          key: Date.now().toString(),
          isMe: true,
          name: currentUser.name,
          shortName: currentUser.shortName,
          score: homeTeam + '-' + awayTeam,
          id: currentUser.id,
          picture: currentUser.picture,
        },
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      if (isModal) {
        return null;
      }
      return rejectWithValue('error');
    }
  },
);

const saveMatchResultMultiple = createAsyncThunk(
  'match/saveMatchResultMultiple',
  async (
    { id, homeTeam, awayTeam, isModal, navigate }: SaveMatchResult,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      const currentUser = userSelectors.getUser(state);
      if (!currentUser) {
        return;
      }
      await fetchHandler(
        `${appSelectors.getUrl()}/matches/${id}/result/multiple`,
        {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'POST',
          body: JSON.stringify({
            score: `${homeTeam}-${awayTeam}`,
          }),
        },
      );

      dispatch(appActions.setCacheState({ key: 'match' }));
      dispatch(appActions.setModalState({ modal: undefined }));
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      if (isModal) {
        navigate && navigate('TabNavigator');
      }

      return {
        id,
        user: {
          mid: id,
          key: Date.now().toString(),
          isMe: true,
          name: currentUser.name,
          shortName: currentUser.shortName,
          score: homeTeam + '-' + awayTeam,
          id: currentUser.id,
          picture: currentUser.picture,
        },
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      if (isModal) {
        return null;
      }
      return rejectWithValue('error');
    }
  },
);

const mapMatches = (matches: matchTypes.IMatch[]) => {
  const matchesFromApi: matchTypes.IMatch[] = matches.map(match => ({
    ...match,
  }));
  return matchesFromApi;
};

const refreshMatches = createAsyncThunk(
  'match/refreshMatches',
  async (
    showLoader: boolean,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(
        matchSlice.actions.setLoaderState({
          loader: showLoader,
        }),
      );
      const { matches } = await (<
        Promise<{
          matches: IContractMatch[];
        }>
      >fetchHandler(`${appSelectors.getUrl()}/matches`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state, 'match'),
            userSelectors.getToken(state),
          ),
        },
        method: 'GET',
      }));
      dispatch(
        matchSlice.actions.refreshMatchesState({
          matches: mapMatches(matches),
        }),
      );
      dispatch(matchSlice.actions.setLoaderState({ loader: false }));
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const refreshPublicMatches = createAsyncThunk(
  'match/refreshPublicMatches',
  async (
    showLoader: boolean,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(
        matchSlice.actions.setLoaderState({
          loader: showLoader,
        }),
      );
      const { matches } = await (<
        Promise<{
          matches: IContractMatch[];
        }>
      >fetchHandler(`${appSelectors.getUrl()}/public/matches?bundleIdentifier=${DeviceInfo.getBundleId()}`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state, 'match'),
          ),
        },
        method: 'GET',
      }));
      dispatch(
        matchSlice.actions.refreshMatchesState({
          matches: mapMatches(matches),
        }),
      );
      dispatch(matchSlice.actions.setLoaderState({ loader: false }));
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const matchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    queryMatchesState(state, action) {
      state.query = action.payload.query;
    },
    refreshMatchesState(state, action) {
      state.matches = action.payload.matches;
    },
    setLoaderState(state, action) {
      state.loader = action.payload.loader;
    },
  },
  extraReducers: builder => {
    builder.addCase(saveMatchResult.fulfilled, (state, action) => {
      if (action.payload?.id && action.payload?.user) {
        state.matches = updateMatches(
          state.matches,
          action.payload.id,
          action.payload.user,
        );
      }
    });
    builder.addCase(saveMatchResultMultiple.fulfilled, (state, action) => {
      if (action.payload?.id && action.payload?.user) {
        state.matches = updateMatches(
          state.matches,
          action.payload.id,
          action.payload.user,
        );
      }
    });
    builder.addMatcher(shouldClearLoader, state => {
      state.loader = false;
    });
  },
});

export const actions = {
  ...matchSlice.actions,
  refreshMatches,
  saveMatchResult,
  saveMatchResultMultiple,
  refreshPublicMatches,
};

export default matchSlice.reducer;
