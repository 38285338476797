import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { IState } from './types';

export const initialState: IState = {
  loaders: {
    switch: false,
    local: false,
    global: true,
  },
};

const shouldClearLoader = (action: AnyAction) => {
  return action.type === 'error/setError/fulfilled';
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLocalLoaderState(state, action) {
      state.loaders.local = action.payload.loader;
    },
    setGlobalLoaderState(state, action) {
      state.loaders.global = action.payload.loader;
    },
    setSwitchLoaderState(state, action) {
      state.loaders.switch = action.payload.loader;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(shouldClearLoader, state => {
      state.loaders.switch = false;
      state.loaders.local = false;
      state.loaders.global = false;
    });
  },
});

export const actions = loaderSlice.actions;

export default loaderSlice.reducer;
