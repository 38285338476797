import { I18n } from 'i18n-js';
import { GetObjectCommandOutput } from '@aws-sdk/client-s3';
import { DateTime } from 'luxon';
import {
  IAsyncStorage,
  ISentry,
  ILocale,
  ILocales,
  II18nManager,
} from '@football/types/app';
import { fetchHandler } from '@football/api';

import en from './en.json';

const i18n = new I18n();

i18n.defaultLocale = 'en';
i18n.enableFallback = true;

export const supportedLanguages = [
  'ar',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'pl',
  'pt',
  'ru',
  'sv',
];

const getLanguagesFromBucket = (
  getObject: (key: string) => Promise<GetObjectCommandOutput | undefined>,
  languages: string[],
) =>
  Promise.all(
    languages.map(async language => {
      const response = await getObject(`${language}.json`);
      const body = response?.Body
        ? await response.Body.transformToString('utf-8')
        : '';
      return {
        key: language,
        value: JSON.parse(body),
      };
    }),
  );

const initLanguagesFromBucket = async (
  getObject: (key: string) => Promise<GetObjectCommandOutput | undefined>,
) => {
  if (process.env.NODE_ENV === 'test') {
    i18n.translations = { en };
    return;
  }

  const languages = await getLanguagesFromBucket(getObject, supportedLanguages);
  const translations = languages.reduce(
    (obj, item) => ({
      ...obj,
      [item.key]: item.value,
    }),
    {},
  );
  i18n.translations = translations;
};

export const storeLanguages = async (
  AsyncStorage?: IAsyncStorage,
  translations?: { translations: string[] },
  days?: number,
) => {
  await AsyncStorage?.setItem(
    '@language',
    JSON.stringify({
      ...translations,
      expireDate: DateTime.local().plus({ days }),
    }),
  );
};

export const storeLocal = async (
  AsyncStorage: IAsyncStorage,
  locale: ILocale,
) => {
  await AsyncStorage.setItem('@locale', JSON.stringify(locale));
};

export const retrieveLocale = async (
  AsyncStorage?: IAsyncStorage,
  getLocales?: ILocales,
): Promise<ILocale> => {
  const locales = getLocales && getLocales();
  const locale = locales ? locales[0] : null;
  const storedLocale = await AsyncStorage?.getItem('@locale');
  return storedLocale ? JSON.parse(storedLocale) : locale;
};

export const retrieveLanguages = async (AsyncStorage?: IAsyncStorage) => {
  const languages = await AsyncStorage?.getItem('@language');
  return languages || '{}';
};

export const getLanguagesFromUrl = (languages: string[]) => {
  return Promise.all(
    languages.map(async (language: string) => {
      const json = await fetchHandler(
        'https://football.srvc.io/translations/v2/' + language + '.json',
        {
          method: 'GET',
        },
      );
      return {
        key: language,
        value: json,
      };
    }),
  );
};

export const setRtl = (I18nManager?: II18nManager, isRTL?: boolean) => {
  I18nManager?.forceRTL(isRTL || false);
};

const initLanguagesFromUrl = async ({
  AsyncStorage,
  Sentry,
  days,
  initWithlanguages,
  I18nManager,
  getLocales,
}: {
  I18nManager?: II18nManager;
  AsyncStorage?: IAsyncStorage;
  Sentry?: ISentry;
  days?: number;
  initWithlanguages?: { [locale: string]: object };
  getLocales?: ILocales;
}) => {
  if (initWithlanguages) {
    i18n.translations = initWithlanguages;
    return;
  }

  if (process.env.NODE_ENV === 'test') {
    i18n.translations = { en };
    return;
  }

  try {
    const locale = await retrieveLocale(AsyncStorage, getLocales);

    i18n.locale = locale.languageCode;

    setRtl(I18nManager, locale.isRTL);

    const retrievedLanguages = await retrieveLanguages(AsyncStorage);
    const parsedLanguages = JSON.parse(retrievedLanguages);
    const expireDate = parsedLanguages.expireDate;
    if (
      parsedLanguages &&
      parsedLanguages.expireDate &&
      parsedLanguages.translations
    ) {
      const diff = Math.round(
        DateTime.fromISO(expireDate).diffNow('days').days,
      );
      if (diff > 0) {
        i18n.translations = parsedLanguages.translations;
        return;
      }
    }

    if (supportedLanguages.includes(i18n.locale)) {
      const languages = await getLanguagesFromUrl([i18n.locale]);
      const translations = languages.reduce((obj, item) => {
        return {
          ...obj,
          [item.key]: item.value,
        };
      }, []);
      i18n.translations = translations;
      await storeLanguages(AsyncStorage, { translations }, days);
    } else {
      console.log(
        `Failed to find supported language for locale ${i18n.locale}`,
      );
      Sentry?.captureMessage(
        `Failed to find supported language for locale ${i18n.locale}`,
      );
      i18n.locale = 'en';
      i18n.translations = { en };
    }
  } catch (error) {
    console.log('Failed to boot languages');
    Sentry?.captureMessage('Failed to boot languages');
    i18n.locale = 'en';
    i18n.translations = { en };
  }
};

export { i18n, initLanguagesFromBucket, initLanguagesFromUrl };
