import React from 'react';
import styled, { css } from 'styled-components/native';

interface IBaseProps {
  center?: boolean;
  subtitle?: boolean;
  capitalize?: boolean;
  title?: boolean;
  light?: boolean;
}
interface IProps extends IBaseProps {
  children: React.ReactNode;
}

const center = css`
  text-align: center;
`;

const subtitle = css`
  font-size: 16px;
`;

const capitalize = css`
  text-transform: capitalize;
`;

const title = css`
  font-size: 20px;
`;

const light = css`
  color: ${props => props.theme.colors.gray[500]};
`;

const StyledText = styled.Text<IBaseProps>`
  font-size: 18px;
  font-family: FjallaOne-Regular;
  color: ${props => props.theme.colors.gray[900]};
  ${props => props.center && center}
  ${props => props.subtitle && subtitle}
  ${props => props.capitalize && capitalize}
  ${props => props.title && title}
  ${props => props.light && light}
`;

const Header = (props: IProps) => {
  return <StyledText {...props}>{props.children}</StyledText>;
};

export default Header;
