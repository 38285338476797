import { actions as appActions } from './';
import { actions as scoreActions } from '../score';
import { actions as matchActions } from '../match';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { actions as productActions } from '../product';
import { actions as groupActions } from '../group';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: appActions.setTrigger,
  effect: async (action, listenerApi) => {
    if (action.payload === 'saveUser' || action.payload === 'activateGroup') {
      listenerApi.dispatch(matchActions.refreshMatches(true));
      listenerApi.dispatch(scoreActions.refreshScores(true));
    }
    if (action.payload === 'joinGroup') {
      listenerApi.dispatch(groupActions.refreshGroups());
    }
  },
});

listenerMiddleware.startListening({
  actionCreator: productActions.resetProductState,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      appActions.setDisableBackgroundModeState({
        disableBackgroundMode: false,
      }),
    );
  },
});

listenerMiddleware.startListening({
  actionCreator: productActions.unLockGroupState,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      appActions.setDisableBackgroundModeState({
        disableBackgroundMode: true,
      }),
    );
  },
});

listenerMiddleware.startListening({
  actionCreator: productActions.unLockInviteState,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      appActions.setDisableBackgroundModeState({
        disableBackgroundMode: true,
      }),
    );
  },
});

export default listenerMiddleware;
