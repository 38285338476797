import React from 'react';
import styled, { css } from 'styled-components/native';

interface IBaseProps {
  padded?: boolean;
  noBorderBottom?: boolean;
  noPadding?: boolean;
  table?: boolean;
  insetRight?: boolean;
}

interface IListItemProps extends IBaseProps {
  children: React.ReactNode;
}

interface ICounterProps {
  color: string;
  bgColor: string;
  children: React.ReactNode;
}

const padded = css`
  padding: 15px 10px;
`;

const table = css`
  padding: 10px 5px;
`;

const noPadding = css`
  padding: 0;
`;

const insetRight = css`
  margin-right: -10px;
  padding-right: 10px;
`;

const noBorderBottom = css`
  border-bottom-width: 0;
`;

const StyledListItem = styled.View<IBaseProps>`
  border-bottom-color: ${props => props.theme.colors.gray[300]};
  border-bottom-width: 1px;
  padding: 10px;
  flex-direction: row;
  ${props => props.padded && padded}
  ${props => props.insetRight && insetRight}
  ${props => props.noBorderBottom && noBorderBottom}
  ${props => props.noPadding && noPadding}
  ${props => props.table && table}
`;

export const ListItem = (props: IListItemProps) => {
  return <StyledListItem {...props}>{props.children}</StyledListItem>;
};

const StyledCounter = styled.View<ICounterProps>`
  width: 20px;
  height: 20px;
  align-items: center;
  background-color: ${props => props.bgColor};
  border-radius: 20px;
`;

const StyledCounterText = styled.Text<ICounterProps>`
  line-height: 20px;
  font-size: 10px;
  color: ${props => props.color};
`;

export const Counter = (props: ICounterProps) => {
  return (
    <StyledCounter {...props}>
      <StyledCounterText {...props}>{props.children}</StyledCounterText>
    </StyledCounter>
  );
};
