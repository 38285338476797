import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PurchaseError } from 'react-native-iap';

import { IRootState, IExtraArgument } from '@football/types/app';
import { selectors as userSelectors } from '../user';

import { IState } from './types';
import { FetchError } from '@football/api';

const initialState: IState = {};

const setError = createAsyncThunk(
  'error/setError',
  (error: Error | FetchError | PurchaseError, { extra, getState }) => {
    const { Sentry } = extra as IExtraArgument;
    const state = getState() as IRootState;

    const currentUser = userSelectors.getUser(state);
    const loggedIn = userSelectors.getLoggedIn(state);

    if (loggedIn && currentUser) {
      Sentry.setUser({
        email: currentUser.email,
        id: currentUser.id,
      });
    }

    console.error(error);
    Sentry.captureException(error);

    if (error instanceof FetchError && error.status === 408) {
      return null;
    }

    return {
      error: {
        message: error.message,
        name: error.name,
      },
    };
  },
);

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    resetErrorState() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(setError.fulfilled, (state, action) => {
      if (action?.payload) {
        state.message = action.payload.error.message;
        state.name = action.payload.error.name;
      }
    });
  },
});

export const actions = {
  ...errorSlice.actions,
  setError,
};

export default errorSlice.reducer;
