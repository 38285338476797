import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { selectors as appSelectors, actions as appActions } from '../app/';
import { actions as errorActions } from '../error';
import { fetchHandler, getHeaders } from '@football/api';
import { IRootState, IExtraArgument } from '@football/types/app';
import { selectors as userSelectors } from '../user';
import { IState } from './types';
import { IContractPlayer } from '@football/types';

const initialState: IState = {
  players: [],
  selectedPlayer: [],
  query: '',
};

interface ISavePlayer {
  gid: number;
  player: number;
}

const savePlayer = createAsyncThunk(
  'player/savePlayer',
  async (
    { gid, player }: ISavePlayer,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const { players, selectedPlayer } = await (<Promise<IContractPlayer>>(
        fetchHandler(`${appSelectors.getUrl()}/players`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'POST',
          body: JSON.stringify({
            player,
            gid,
          }),
        })
      ));
      dispatch(appActions.setCacheState({ key: 'player' }));
      return {
        players,
        selectedPlayer,
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const listPlayers = createAsyncThunk(
  'player/listPlayers',
  async (gid: number, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const { players, selectedPlayer } = await (<Promise<IContractPlayer>>(
        fetchHandler(`${appSelectors.getUrl()}/players?gid=${gid}`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state, 'player'),
              userSelectors.getToken(state),
            ),
          },
          method: 'GET',
        })
      ));
      return {
        players,
        selectedPlayer,
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    queryPlayersState(state, action) {
      state.query = action.payload.query;
    },
  },
  extraReducers: builder => {
    builder.addCase(listPlayers.fulfilled, (state, action) => {
      state.players = action.payload.players;
      state.selectedPlayer = action.payload.selectedPlayer;
    });
    builder.addCase(savePlayer.fulfilled, (state, action) => {
      state.players = action.payload.players;
      state.selectedPlayer = action.payload.selectedPlayer;
    });
  },
});

export const actions = {
  ...playerSlice.actions,
  listPlayers,
  savePlayer,
};

export default playerSlice.reducer;
