import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IModal, IState, CacheKey } from './types';
import { selectors as appSelectors } from './';
import { fetchHandler, getHeaders } from '@football/api';
import { IExtraArgument } from '@football/types/app';
import { initLanguagesFromUrl } from '@football/localization';
import { actions as errorActions } from '../error';
import { IContractApp } from '@football/types';

const initialCache = Date.now().toString();

export const initialState: IState = {
  active: true,
  id: 0,
  standings: [],
  bundleIdentifier: '',
  urlScheme: '',
  config: {
    importUrl: '',
    aud: '',
    cache: 0,
    days: 0,
    sid: 0,
    store: {
      apple: '',
      google: '',
    },
    urlScheme: '',
    name: '',
    sum: 0,
    limit: 0,
    search: 0,
    sorting: '',
    filter: {
      interval: {
        start: 0,
        end: 0,
      },
      coming: {
        days: 0,
      },
      active: '',
    },
    features: {
      login: true,
      register: true,
      group: true,
      invite: true,
      match: true,
      join: true,
      meta: false,
      playoff: false,
      rtl: false,
    },
    ads: {
      enabled: false,
      ratio: 2,
      partner: false,
    },
  },
  backgroundMode: false,
  disableBackgroundMode: false,
  colorScheme: 'light',
  cache: {
    app: initialCache,
    group: initialCache,
    match: initialCache,
    notification: initialCache,
    player: initialCache,
    score: initialCache,
    team: initialCache,
    standing: initialCache,
  },
  store: {
    ios: '',
    android: '',
  },
  sid: 0,
};

const setApp = createAsyncThunk(
  'app/setApp',
  async (
    shouldInitLanguagesFromUrl: boolean,
    { extra, rejectWithValue, dispatch },
  ) => {
    const {
      Sentry,
      DeviceInfo,
      AsyncStorage,
      getLocales,
      I18nManager,
      Platform,
    } = extra as IExtraArgument;

    if (shouldInitLanguagesFromUrl) {
      await initLanguagesFromUrl({
        AsyncStorage,
        Sentry,
        days: 7,
        getLocales,
        I18nManager,
      });
    }

    try {
      const app = await (<Promise<IContractApp>>(
        fetchHandler(
          `${appSelectors.getUrl()}/apps?bundleIdentifier=${DeviceInfo.getBundleId()}`,
          {
            headers: {
              ...getHeaders(DeviceInfo, Platform),
            },
            method: 'GET',
          },
        )
      ));
      const active = (await AsyncStorage.getItem('@search.filter.active')) as
        | 'coming'
        | 'interval'
        | '';
      if (active && app.config?.filter?.active) {
        app.config.filter.active = active;
      }
      return { app };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const setFilter = createAsyncThunk(
  'app/setFilter',
  async (active: 'coming' | 'interval', { extra }) => {
    const { AsyncStorage } = extra as IExtraArgument;
    await AsyncStorage.setItem('@search.filter.active', active);
    return { active };
  },
);

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setBackgroundModeState(state, action) {
      if (state.disableBackgroundMode) {
        state.backgroundMode = 'disabled';
      } else {
        state.backgroundMode = action.payload.backgroundMode;
      }
    },
    setThemeState(state, action) {
      state.colorScheme = action.payload.colorScheme;
    },
    setCacheState(state, action) {
      state.cache[action.payload.key as CacheKey] = Date.now().toString();
    },
    setModalState(state: IState, action: { payload: { modal?: IModal } }) {
      state.modal = action.payload.modal;
    },
    setDisableBackgroundModeState(state, action) {
      state.disableBackgroundMode = action.payload.disableBackgroundMode;
    },
    setRoute(state, action) {
      state.route = action.payload.route;
    },
    setTrigger(state, {}) {
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(setApp.fulfilled, (state, action) => {
      state.id = action.payload.app.id;
      state.urlScheme = action.payload.app.urlScheme;
      state.config = action.payload.app.config;
      state.message = action.payload.app.message;
      state.store = action.payload.app.store;
    });
    builder.addCase(setFilter.fulfilled, (state, action) => {
      state.config.filter.active = action.payload.active;
    });
  },
});

export const actions = {
  ...appSlice.actions,
  setApp,
  setFilter,
};

export default appSlice.reducer;
