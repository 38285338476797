import React from 'react';
import styled from 'styled-components/native';

interface IProps {
  children: React.ReactNode;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
}

const StyledView = styled.View<IProps>`
  ${props => (props.mt ? 'margin-top:' + props.mt + 'px' : '')};
  ${props => (props.mr ? 'margin-right:' + props.mr + 'px' : '')};
  ${props => (props.mb ? 'margin-bottom:' + props.mb + 'px' : '')};
  ${props => (props.ml ? 'margin-left:' + props.ml + 'px' : '')};
`;

const Spacing = (props: IProps) => {
  return <StyledView {...props}>{props.children}</StyledView>;
};

export default Spacing;
