/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { I18nManager, Switch } from 'react-native';
import { DateTime } from 'luxon';

import { selectors as appSelectors } from '@football/modules/app';
import {
  actions as matchActions,
  types as matchTypes,
} from '@football/modules/match';
import { i18n } from '@football/localization';
import {
  Button,
  Input,
  Wrapper,
  Paragraph,
  Spacing,
  Image,
  Header,
} from '../components';
import { selectors as errorSelectors } from '@football/modules/error';
import {
  getLocalizedError,
  useAppDispatch,
  useAppSelector,
} from '@football/app-utils';
import { types as groupTypes } from '@football/modules/group';

const ResultWrapper = styled.View`
  ${
    // @ts-ignore
    props => {
      return props;
    }
  };
`;

type INavigationParamList = {
  TabNavigator?: Record<string, never>;
};

interface IProps {
  match: matchTypes.IMatch;
  modal?: boolean;
  navigation?: NativeStackNavigationProp<INavigationParamList>;
  group: groupTypes.IGroup | null;
}

const haveMatchStarted = (match: matchTypes.IMatch) => {
  return (
    Math.round(DateTime.fromISO(match.utcDate).diffNow('minutes').minutes) <= 0
  );
};

export const MatchAction = (props: IProps) => {
  const dispatch = useAppDispatch();
  const features = useAppSelector(appSelectors.getFeatures);
  const error = useAppSelector(errorSelectors.getError);

  const me = props.match.users.find((user: matchTypes.IMatchUser) => user.isMe);
  const score = me?.score?.split('-');

  const [homeTeam, setHomeTeam] = useState<string>(score ? score[0] : '');
  const [awayTeam, setAwayTeam] = useState<string>(score ? score[1] : '');
  const [multiple, setMultiple] = useState<boolean>(false);

  const changeHomeTeam = (newHomeTeam: string) => {
    setHomeTeam(newHomeTeam);
  };

  const changeAwayTeam = (newAwayTeam: string) => {
    setAwayTeam(newAwayTeam);
  };

  const theme = useTheme();
  const { colors } = theme;

  const saveMatchResult = async () => {
    if (homeTeam && awayTeam) {
      if (multiple) {
        await dispatch(
          matchActions.saveMatchResultMultiple({
            id: props.match.id,
            homeTeam,
            awayTeam,
            isModal: props.modal,
            navigate: props.navigation?.navigate,
          }),
        );
      } else {
        await dispatch(
          matchActions.saveMatchResult({
            id: props.match.id,
            homeTeam,
            awayTeam,
            isModal: props.modal,
            navigate: props.navigation?.navigate,
          }),
        );
      }
    }
  };

  const onSaveMultiple = (value: boolean) => {
    setMultiple(value);
  };

  const flex = props.modal ? { flexible: true } : { flexible: false };

  const homeTeamGoals = props.match.goals?.filter(goal => {
    return goal.team.id === props.match.meta?.homeTeam.id;
  });

  const awayTeamGoals = props.match.goals?.filter(goal => {
    return goal.team.id === props.match.meta?.awayTeam.id;
  });

  const haveHomeTeamGoals = homeTeamGoals && homeTeamGoals.length > 0;

  const haveAwayTeamGoals = awayTeamGoals && awayTeamGoals.length > 0;

  return (
    <Wrapper {...flex}>
      <ResultWrapper flex-direction={I18nManager.isRTL ? 'row-reverse' : 'row'}>
        <Wrapper flexible>
          <Wrapper centerItems>
            <Spacing mb={15}>
              <Image picture={props.match.meta?.homeTeam.picture} />
            </Spacing>
            <Spacing mb={20}>
              <Header center>{props.match.homeTeam.name}</Header>
            </Spacing>
          </Wrapper>
          <Spacing mb={props.group && !haveMatchStarted(props.match) ? 20 : 0}>
            {props.group && !haveMatchStarted(props.match) ? (
              <Wrapper centerItems>
                <Input
                  testID="matchTextInputHomeTeam"
                  width={100}
                  textAlign="center"
                  keyboardType="numeric"
                  returnKeyType="done"
                  onChangeText={changeHomeTeam}
                  autoCapitalize="none"
                  value={homeTeam}
                  autoCorrect={false}
                  placeholder={i18n.translate(
                    'match.textInputHomeTeam.placeHolder',
                  )}
                />
              </Wrapper>
            ) : (
              <>
                <Wrapper
                  {...(haveHomeTeamGoals || haveAwayTeamGoals
                    ? { borderBottom: true }
                    : undefined)}
                  centerItems>
                  <Spacing
                    {...(haveHomeTeamGoals || haveAwayTeamGoals
                      ? { mb: 20 }
                      : undefined)}>
                    <Paragraph title bold>
                      {props.match.homeTeam.score}
                      {props.match.homeTeam.score !==
                        props.match.homeTeam.scoreMatchTime ||
                      props.match.awayTeam.score !==
                        props.match.awayTeam.scoreMatchTime
                        ? ' (' + props.match.homeTeam.scoreMatchTime + ')'
                        : ''}
                    </Paragraph>
                  </Spacing>
                </Wrapper>
                {haveHomeTeamGoals && (
                  <Wrapper centerItems>
                    <Spacing mt={20}>
                      {homeTeamGoals?.map(goal => {
                        return (
                          <Spacing mt={5} mb={5}>
                            <Wrapper row>
                              <Spacing mr={5}>
                                <Paragraph>{goal.scorer.name}</Paragraph>
                              </Spacing>
                              <Paragraph bold>{goal.minute}'</Paragraph>
                            </Wrapper>
                          </Spacing>
                        );
                      })}
                    </Spacing>
                  </Wrapper>
                )}
              </>
            )}
          </Spacing>
        </Wrapper>
        <Wrapper flexible>
          <Wrapper centerItems>
            <Spacing mb={15}>
              <Image picture={props.match.meta?.awayTeam.picture} />
            </Spacing>
            <Spacing mb={20}>
              <Header center>{props.match.awayTeam.name}</Header>
            </Spacing>
          </Wrapper>
          <Spacing mb={props.group && !haveMatchStarted(props.match) ? 20 : 0}>
            {props.group && !haveMatchStarted(props.match) ? (
              <Wrapper centerItems>
                <Input
                  testID="matchTextInputAwayteam"
                  width={100}
                  textAlign="center"
                  keyboardType="numeric"
                  returnKeyType="done"
                  onChangeText={changeAwayTeam}
                  autoCapitalize="none"
                  value={awayTeam}
                  autoCorrect={false}
                  placeholder={i18n.translate(
                    'match.textInputAwayteam.placeHolder',
                  )}
                />
              </Wrapper>
            ) : (
              <>
                <Wrapper
                  {...(haveAwayTeamGoals || haveHomeTeamGoals
                    ? { borderBottom: true }
                    : undefined)}
                  centerItems>
                  <Spacing
                    {...(haveAwayTeamGoals || haveHomeTeamGoals
                      ? { mb: 20 }
                      : undefined)}>
                    <Paragraph title bold>
                      {props.match.awayTeam.score}
                      {props.match.awayTeam.score !==
                        props.match.awayTeam.scoreMatchTime ||
                      props.match.homeTeam.score !==
                        props.match.homeTeam.scoreMatchTime
                        ? ' (' + props.match.awayTeam.scoreMatchTime + ')'
                        : ''}
                    </Paragraph>
                  </Spacing>
                </Wrapper>
                {haveAwayTeamGoals && (
                  <Wrapper centerItems>
                    <Spacing mt={20}>
                      {awayTeamGoals?.map(goal => {
                        return (
                          <Spacing mt={5} mb={5}>
                            <Wrapper row>
                              <Spacing mr={5}>
                                <Paragraph>{goal.scorer.name}</Paragraph>
                              </Spacing>
                              <Paragraph bold>{goal.minute}'</Paragraph>
                            </Wrapper>
                          </Spacing>
                        );
                      })}
                    </Spacing>
                  </Wrapper>
                )}
              </>
            )}
          </Spacing>
        </Wrapper>
      </ResultWrapper>
      {props.group && !haveMatchStarted(props.match) && (
        <>
          <Spacing mb={20}>
            <Wrapper centerItems>
              <Wrapper row centerItems>
                <Paragraph bold>Save result on all groups</Paragraph>
                <Spacing ml={10}>
                  <Switch
                    trackColor={{
                      true: colors.green[700],
                      false: colors.red[700],
                    }}
                    accessibilityLabel="Save result on all groups"
                    ios_backgroundColor={colors.gray[300]}
                    value={multiple}
                    onValueChange={value => onSaveMultiple(value)}
                  />
                </Spacing>
              </Wrapper>
            </Wrapper>
          </Spacing>
          <Button
            disabled={!features.match}
            primary
            validate
            testID="matchButtonSaveResult"
            text={i18n.translate('match.buttonSaveResult')}
            onPress={saveMatchResult}
          />
        </>
      )}
      {props.modal && error.message && (
        <Spacing mt={20}>
          <Paragraph center inactive>
            {getLocalizedError(i18n.translate, error)}
          </Paragraph>
        </Spacing>
      )}
    </Wrapper>
  );
};

export default MatchAction;
