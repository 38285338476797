import { AnyAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IState } from './types';
import * as appSelectors from '../app/selectors';
import { actions as errorActions } from '../error';
import { fetchHandler, getHeaders } from '@football/api';
import { IExtraArgument, IRootState } from '@football/types/app';
import { IContractPlayoff, IContractStanding } from '@football/types';
import { selectors as userSelectors } from '../user';

export const initialState: IState = {
  standings: [],
  playoffs: [],
  loader: false,
};

const shouldClearLoader = (action: AnyAction) => {
  return action.type === 'error/setError/fulfilled';
};

const refreshPublicStandings = createAsyncThunk(
  'standing/refreshPublicStandings',
  async (
    showLoader: boolean,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(
        actions.setLoaderState({
          loader: showLoader,
        }),
      );
      const { standings, playoffs } = await (<
        Promise<{
          playoffs: IContractPlayoff[];
          standings: IContractStanding[];
        }>
      >fetchHandler(`${appSelectors.getUrl()}/public/standings?bundleIdentifier=${DeviceInfo.getBundleId()}`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state, 'standing'),
          ),
        },
        method: 'GET',
      }));
      dispatch(
        standingSlice.actions.setLoaderState({
          loader: false,
        }),
      );
      return { standings, playoffs };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const refreshStandings = createAsyncThunk(
  'standing/refreshStandings',
  async (
    showLoader: boolean,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(
        actions.setLoaderState({
          loader: showLoader,
        }),
      );
      const { standings, playoffs } = await (<
        Promise<{
          playoffs: IContractPlayoff[];
          standings: IContractStanding[];
        }>
      >fetchHandler(`${appSelectors.getUrl()}/standings`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state, 'standing'),
            userSelectors.getToken(state),
          ),
        },
        method: 'GET',
      }));
      dispatch(
        standingSlice.actions.setLoaderState({
          loader: false,
        }),
      );
      return { standings, playoffs };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const standingSlice = createSlice({
  name: 'standing',
  initialState,
  reducers: {
    setLoaderState(state, action) {
      state.loader = action.payload.loader;
    },
  },
  extraReducers: builder => {
    builder.addCase(refreshPublicStandings.fulfilled, (state, action) => {
      state.playoffs = action.payload.playoffs;
      state.standings = action.payload.standings;
    });
    builder.addCase(refreshStandings.fulfilled, (state, action) => {
      state.playoffs = action.payload.playoffs;
      state.standings = action.payload.standings;
    });
    builder.addMatcher(shouldClearLoader, state => {
      state.loader = false;
    });
  },
});

export const actions = {
  ...standingSlice.actions,
  refreshPublicStandings,
  refreshStandings,
};

export default standingSlice.reducer;
