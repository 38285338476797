import React from 'react';
import styled, { css } from 'styled-components/native';
import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';

import { getStatusBarHeightByPlatForm } from '@football/app-utils';

interface IBaseProps {
  center?: boolean;
  active?: boolean;
  inactive?: boolean;
  italic?: boolean;
  divider?: boolean;
  light?: boolean;
  bold?: boolean;
  title?: boolean;
  inverted?: boolean;
  small?: boolean;
  truncate?: boolean;
}

interface IProps extends IBaseProps {
  children: React.ReactNode;
}

const center = css`
  text-align: center;
`;

const active = css`
  color: ${props => props.theme.colors.green[700]};
`;

const inactive = css`
  color: ${props => props.theme.colors.red[700]};
`;

const light = css`
  color: ${props => props.theme.colors.gray[50]};
`;

const italic = css`
  font-family: Raleway-LightItalic;
`;

const bold = css`
  font-family: Raleway-Medium;
`;

const title = css`
  font-size: 16px;
`;

const divider = css`
  height: 39px;
  margin-top: 5px;
`;

const inverted = css`
  background-color: ${props => props.theme.colors.red[700]};
  padding: 5px 0;
`;

const height = getStatusBarHeightByPlatForm(Platform, DeviceInfo, 30, 30);

const small = css`
  height: ${height}px;
  line-height: ${height}px;
`;

const StyledText = styled.Text<IBaseProps>`
  color: ${props => props.theme.colors.gray[700]};
  font-family: Raleway-Light;
  font-size: 14px;
  ${props => props.center && center}
  ${props => props.active && active}
  ${props => props.inactive && inactive}
  ${props => props.italic && italic}
  ${props => props.divider && divider}
  ${props => props.light && light}
  ${props => props.bold && bold}
  ${props => props.title && title}
  ${props => props.inverted && inverted}
  ${props => props.small && small}
`;

const Paragraph = (props: IProps) => {
  return (
    <StyledText
      {...(props.truncate ? { numberOfLines: 1 } : undefined)}
      {...props}>
      {props.children}
    </StyledText>
  );
};

export default Paragraph;
