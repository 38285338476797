import React, { useEffect } from 'react';
import { ThemeProvider, useTheme } from 'styled-components/native';
import { Appearance, ActivityIndicator } from 'react-native';

import {
  Header,
  Paragraph,
  Spacing,
  Wrapper,
} from '@football/components/components';
import {
  selectors as scoreSelectors,
  actions as scoreActions,
} from '@football/modules/score';
import {
  selectors as appSelectors,
  actions as appActions,
} from '@football/modules/app';

import { useAppDispatch, useAppSelector } from '@football/app-utils';
import { ListScores } from '@football/components/lists';
import { Filter } from '@football/components/modals';
import { i18n, initLanguagesFromUrl } from '@football/localization';
import en from './localization/en.json';

const Scores = () => {
  const personScores = useAppSelector(scoreSelectors.getPersonScores);
  const countryScores = useAppSelector(scoreSelectors.getCountryScores);
  const groupScores = useAppSelector(scoreSelectors.getGroupScores);
  const loader = useAppSelector(scoreSelectors.getLoader);
  const filter = useAppSelector(scoreSelectors.getFilter);
  const features = useAppSelector(appSelectors.getFeatures);

  const theme = useTheme();
  const { colors } = theme;

  if (loader) {
    return (
      <Wrapper flexible row centerContent>
        <ActivityIndicator
          accessible={false}
          importantForAccessibility="no"
          accessibilityElementsHidden={true}
          size="large"
          color={colors.gray[700]}
        />
      </Wrapper>
    );
  }

  const scores = {
    person: personScores,
    country: countryScores,
    group: groupScores,
  };

  return (
    <>
      <Filter onCloseModal={() => null} />
      <Wrapper borderBottom centerItems>
        <Spacing mb={20}>
          {filter === 'person' && features.meta && (
            <Paragraph italic center>
              {i18n.translate('score.person.title')}
            </Paragraph>
          )}
          {filter === 'group' && (
            <Paragraph italic center>
              {i18n.translate('score.group.title')}
            </Paragraph>
          )}
          {filter === 'country' && (
            <Paragraph italic center>
              {i18n.translate('score.country.title')}
            </Paragraph>
          )}
        </Spacing>
      </Wrapper>
      <ListScores filter={filter} data={scores[filter] || []} />
    </>
  );
};

const getApp = (hostname: string) => {
  const params = new URL(window.location.href).searchParams;
  switch (params.get('hostname') ? params.get('hostname') : hostname) {
    case 'world-cup-2023.football.srvc.io':
      return {
        headline:
          'Thank you all for participating in the FIFA World Cup 2023 Predictor!',
        year: 2023,
        nextYear: 'We will see you again for the Mens UEFA Euro 2024.',
        sid: 1563,
      };
    case 'euro-2024.football.srvc.io':
      return {
        headline:
          'Thank you all for participating in the UEFA Euro 2024 Predictor!',
        year: 2024,
        nextYear: "We will see you again for the Women's UEFA Euro 2025.",
        sid: 1537,
      };
    default:
      return {};
  }
};

const Web = () => {
  const dispatch = useAppDispatch();
  const colors = useAppSelector(appSelectors.getColors);

  const app = getApp(window.location.hostname);

  useEffect(() => {
    const init = async () => {
      await initLanguagesFromUrl({
        initWithlanguages: {
          en,
        },
      });
      dispatch(
        appActions.setThemeState({
          colorScheme: Appearance.getColorScheme(),
        }),
      );

      dispatch(
        scoreActions.refreshPublicScores({ showLoader: true, sid: app.sid }),
      );
    };
    init();
  }, [dispatch, app.sid]);

  return (
    <ThemeProvider
      theme={{
        colors,
      }}>
      <Wrapper padded flexible>
        <Spacing mb={15}>
          <Header center title>
            {app.headline}
          </Header>
        </Spacing>
        <Spacing mb={30}>
          <Paragraph center title>
            {app.nextYear}
          </Paragraph>
        </Spacing>
        <Scores />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Web;
