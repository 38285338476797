import { AnyAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IState } from './types';
import { fetchHandler, getHeaders } from '@football/api';
import { IRootState, IExtraArgument } from '@football/types/app';
import { actions as errorActions } from '../error';
import { selectors as appSelectors } from '../app';
import { selectors as userSelectors } from '../user';
import { IContractScore } from '@football/types';

export const initialState: IState = {
  filter: 'person',
  scores: [],
  public: {
    person: [],
    group: [],
    country: [],
  },
  loader: false,
};

const shouldClearLoader = (action: AnyAction) => {
  return action.type === 'error/setError/fulfilled';
};

interface IRefreshScores {
  scores: IContractScore[];
  public: {
    person: IContractScore[];
    group: IContractScore[];
    country: IContractScore[];
  };
}

const refreshScores = createAsyncThunk(
  'score/refreshScores',
  async (
    showLoader: boolean,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(
        scoreSlice.actions.setLoaderState({
          loader: showLoader,
        }),
      );
      const {
        scores,
        public: { person, group, country },
      } = await (<Promise<IRefreshScores>>(
        fetchHandler(`${appSelectors.getUrl()}/scores`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state, 'score'),
              userSelectors.getToken(state),
            ),
          },
          method: 'GET',
        })
      ));
      dispatch(
        scoreSlice.actions.setLoaderState({
          loader: false,
        }),
      );
      return { scores, person, group, country };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

interface IRefreshPublicScores {
  person: IContractScore[];
  group: IContractScore[];
  country: IContractScore[];
}

interface IRefreshPublicScoresArgs {
  showLoader: boolean;
  sid?: number;
}

const refreshPublicScores = createAsyncThunk(
  'score/refreshPublicScores',
  async (
    { sid, showLoader }: IRefreshPublicScoresArgs,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(
        scoreSlice.actions.setLoaderState({
          loader: showLoader,
        }),
      );
      const { person, group, country } = await (<Promise<IRefreshPublicScores>>(
        fetchHandler(
          `${appSelectors.getUrl()}/public/scores?bundleIdentifier=${DeviceInfo.getBundleId()}&sid=${sid}`,
          {
            headers: {
              ...getHeaders(
                DeviceInfo,
                Platform,
                appSelectors.getCacheKey(state, 'score'),
              ),
            },
            method: 'GET',
          },
        )
      ));
      dispatch(
        scoreSlice.actions.setLoaderState({
          loader: false,
        }),
      );
      return { person, group, country };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const scoreSlice = createSlice({
  name: 'score',
  initialState,
  reducers: {
    setLoaderState(state, action) {
      state.loader = action.payload.loader;
    },
    setFilterState(state, action) {
      state.filter = action.payload.filter;
    },
  },
  extraReducers: builder => {
    builder.addCase(refreshPublicScores.fulfilled, (state, action) => {
      state.public.person = action.payload.person;
      state.public.country = action.payload.country;
      state.public.group = action.payload.group;
    });
    builder.addCase(refreshScores.fulfilled, (state, action) => {
      state.scores = action.payload.scores;
      state.public.person = action.payload.person;
      state.public.country = action.payload.country;
      state.public.group = action.payload.group;
    });
    builder.addMatcher(shouldClearLoader, state => {
      state.loader = false;
    });
  },
});

export const actions = {
  ...scoreSlice.actions,
  refreshScores,
  refreshPublicScores,
};

export default scoreSlice.reducer;
