import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IState } from './types';
import { selectors as userSelectors } from '../user';
import { actions as errorActions } from '../error';
import { fetchHandler, getHeaders } from '@football/api';
import { IRootState, IExtraArgument } from '@football/types/app';
import { selectors as appSelectors, actions as appActions } from '../app/';
import { IContractNotification } from '@football/types';

export const initialState: IState = {
  notifications: [],
};

const refreshNotifications = createAsyncThunk(
  'notification/refreshNotifications',
  async (_, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const notifications = await (<Promise<IContractNotification[]>>(
        fetchHandler(`${appSelectors.getUrl()}/notifications`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state, 'notification'),
              userSelectors.getToken(state),
            ),
          },
          method: 'GET',
        })
      ));
      return { notifications };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

interface ISaveNotification {
  nid: number;
  value: boolean;
}

const saveNotification = createAsyncThunk(
  'notification/saveNotification',
  async (
    { nid, value }: ISaveNotification,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      await fetchHandler(`${appSelectors.getUrl()}/notifications/${nid}`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            userSelectors.getToken(state),
          ),
        },
        method: 'PUT',
        body: JSON.stringify({
          value,
        }),
      });
      dispatch(appActions.setCacheState({ key: 'notification' }));
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const saveMessageToken = createAsyncThunk(
  'notification/saveMessageToken',
  async (
    messageToken: string,
    { extra, dispatch, getState, rejectWithValue },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      await fetchHandler(
        `${appSelectors.getUrl()}/users/${userSelectors.getId(
          state,
        )}/messagetoken`,
        {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'PUT',
          body: JSON.stringify({
            messageToken,
          }),
        },
      );
      return { messageToken };
    } catch (error) {
      dispatch(errorActions.resetErrorState());
      return rejectWithValue('error');
    }
  },
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(refreshNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.notifications;
    });
  },
});

export const actions = {
  ...notificationSlice.actions,
  refreshNotifications,
  saveNotification,
  saveMessageToken,
};

export default notificationSlice.reducer;
