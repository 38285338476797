import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import appMiddleware from '@football/modules/app/middleware';
import * as reducers from '@football/modules/reducers';
import thunk from './extra';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: {
    ...reducers,
  },
  middleware: defaultMiddleware =>
    defaultMiddleware({ thunk }).prepend(appMiddleware.middleware),
  enhancers: getDefaultEnhancers => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});
