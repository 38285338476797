import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '@football/types/app';
import { DateTime } from 'luxon';
import { IMatch } from './types';
import { IFilter } from '../app/types';

export const getMatches = (state: IRootState) => state.match.matches;

const getSearch = (state: IRootState) => state.app.config.search;

export const getQuery = (state: IRootState) => state.match.query;

const getActiveQuery = createSelector(getQuery, getSearch, (query, search) => {
  if (query.length >= search) {
    return query;
  }
  return '';
});

export const getLoader = (state: IRootState) => state.match.loader;

const getFilter = (state: IRootState) => state.app.config.filter;

const getLimit = (state: IRootState) => state.app.config.limit;

const getSorting = (state: IRootState) => state.app.config.sorting;

export const getMatch = (id: number) =>
  createSelector(
    getMatches,
    matches => matches.filter(match => match.id === id)[0],
  );

export const getTodaysMatches = createSelector(getMatches, matches =>
  matches.filter(match => match.isToday),
);

const filterBy = (matches: IMatch[], filter: IFilter) => {
  if (filter.active === 'interval') {
    return matches.filter(match => {
      return (
        DateTime.fromISO(match.utcDate).toFormat('yyyy-LL-dd') >
          DateTime.local()
            .minus({ days: filter.interval.start })
            .toFormat('yyyy-LL-dd') &&
        DateTime.fromISO(match.utcDate).toFormat('yyyy-LL-dd') <
          DateTime.local()
            .plus({ days: filter.interval.end })
            .toFormat('yyyy-LL-dd')
      );
    });
  } else if (filter.active === 'coming') {
    return matches.filter(match => {
      return (
        !match.haveMatchStarted &&
        DateTime.fromISO(match.utcDate).toFormat('yyyy-LL-dd') <
          DateTime.local()
            .plus({ days: filter.coming.days })
            .toFormat('yyyy-LL-dd')
      );
    });
  }
  return matches.filter(match => {
    return (
      !match.haveMatchStarted &&
      DateTime.fromISO(match.utcDate).toFormat('yyyy-LL-dd') <
        DateTime.local()
          .plus({ days: filter.coming.days })
          .toFormat('yyyy-LL-dd')
    );
  });
};

const sortBy = (
  matches: IMatch[],
  activeQuery: string,
  sorting: string,
  filters: IFilter,
) => {
  if (activeQuery) {
    if (sorting === 'date-desc') {
      return [...matches].sort((a, b) => {
        return (
          DateTime.fromISO(b.utcDate).toMillis() -
          DateTime.fromISO(a.utcDate).toMillis()
        );
      });
    } else if (sorting === 'date-asc') {
      return [...matches].sort((a, b) => {
        return (
          DateTime.fromISO(a.utcDate).toMillis() -
          DateTime.fromISO(b.utcDate).toMillis()
        );
      });
    }
  }
  return filterBy(
    [...matches].sort((a, b) => {
      return (
        DateTime.fromISO(a.utcDate).toMillis() -
        DateTime.fromISO(b.utcDate).toMillis()
      );
    }),
    filters,
  );
};

const searchBy = (matches: IMatch[], activeQuery: string) => {
  const search = activeQuery.toLowerCase().split(' ');
  return matches.filter(match => {
    const totalMathes = search.filter(s => {
      return (
        match.name.toLowerCase().search(s) !== -1 ||
        match?.score?.toLowerCase().search(s) !== -1 ||
        DateTime.fromISO(match.utcDate)
          .toFormat('yyyy LLL dd HH:mm')
          .toLowerCase()
          .search(s) !== -1
      );
    });
    return totalMathes.length === search.length;
  });
};

export const getFilteredMatches = createSelector(
  getMatches,
  getActiveQuery,
  getLimit,
  getSorting,
  getFilter,
  (matches, activeQuery, limit, sorting, filter) => {
    if (activeQuery) {
      return sortBy(
        searchBy(matches, activeQuery),
        activeQuery,
        sorting,
        filter,
      ).slice(0, limit);
    }
    return sortBy(matches, activeQuery, sorting, filter).slice(0, limit);
  },
);
