import {
  createSlice,
  UnknownAction,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IState } from './types';
import { actions as loaderActions } from '../loader';
import { selectors as appSelectors, actions as appActions } from '../app/';
import { selectors as userSelectors } from '../user';
import { IRootState, IExtraArgument } from '@football/types/app';
import { actions as errorActions } from '../error';
import { fetchHandler, getHeaders } from '@football/api';
import { IContractGroup } from '@football/types';

export const initialState: IState = {
  groups: [],
  code: '',
};

const shouldClearGroup = (action: UnknownAction) => {
  return action.type === 'user/logoutUserState';
};

function shouldRemoveGroup(
  action: PayloadAction<{ type: string; gid: number }>,
): action is PayloadAction<{ type: string; gid: number }> {
  return action.type === 'user/removeGroup/fulfilled';
}

interface IJoinGroup {
  code: string;
  setCode: (code: string) => void;
  navigate?: (route: string) => void;
}

const joinGroup = createAsyncThunk(
  'group/joinGroup',
  async (
    { code, setCode, navigate }: IJoinGroup,
    { extra, dispatch, getState },
  ) => {
    const { DeviceInfo, AsyncStorage, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const currentUser = userSelectors.getUser(state);
      if (!currentUser) {
        return;
      }
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      const group = await (<Promise<IContractGroup>>(
        fetchHandler(`${appSelectors.getUrl()}/groups/join`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          body: JSON.stringify({
            code,
          }),
          method: 'POST',
        })
      ));

      dispatch(groupSlice.actions.setCodeFromStorageState({ code: '' }));
      await AsyncStorage.setItem('@groupCode', '');
      setCode('');
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(appActions.setCacheState({ key: 'group' }));
      dispatch(appActions.setTrigger('joinGroup'));
      navigate && navigate('TabNavigator');

      return {
        group: {
          ...group,
          users: [
            {
              name: currentUser.name,
              shortName: currentUser.shortName,
              picture: currentUser.picture,
              id: currentUser.id,
              isOwner: false,
              key: Date.now().toString(),
              group: {
                code: group.code,
              },
            },
          ],
        },
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return null;
    }
  },
);

const createGroup = createAsyncThunk(
  'group/createGroup',
  async (name: string, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const currentUser = userSelectors.getUser(state);
      if (!currentUser) {
        return;
      }
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      const group = await (<Promise<IContractGroup>>(
        fetchHandler(`${appSelectors.getUrl()}/groups`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'POST',
          body: JSON.stringify({
            name,
          }),
        })
      ));
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(appActions.setCacheState({ key: 'group' }));
      return {
        group: {
          ...group,
          isOwner: true,
          users: [
            {
              name: currentUser.name,
              shortName: currentUser.shortName,
              picture: currentUser.picture,
              id: currentUser.id,
              isOwner: true,
              key: Date.now().toString(),
              group: {
                code: group.code,
              },
            },
          ],
        },
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const activateGroup = createAsyncThunk(
  'group/activateGroup',
  async (gid: number, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(loaderActions.setSwitchLoaderState({ loader: true }));
      await fetchHandler(`${appSelectors.getUrl()}/groups/${gid}/activate`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            userSelectors.getToken(state),
          ),
        },
        method: 'PUT',
      });
      dispatch({
        type: 'group/active',
        payload: {
          gid,
        },
      });
      dispatch(appActions.setCacheState({ key: 'match' }));
      dispatch(appActions.setCacheState({ key: 'score' }));
      dispatch(appActions.setCacheState({ key: 'group' }));
      dispatch(appActions.setTrigger('activateGroup'));
      dispatch(loaderActions.setSwitchLoaderState({ loader: false }));
      return {
        gid,
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const deleteGroup = createAsyncThunk(
  'group/deleteGroup',
  async (gid: number, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      await fetchHandler(`${appSelectors.getUrl()}/groups/${gid}`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            userSelectors.getToken(state),
          ),
        },
        method: 'DELETE',
      });

      dispatch(appActions.setModalState({ modal: undefined }));
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(appActions.setCacheState({ key: 'group' }));
      return {
        gid,
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const refreshGroups = createAsyncThunk(
  'group/refreshGroups',
  async (_, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const groups = await (<Promise<IContractGroup[]>>(
        fetchHandler(`${appSelectors.getUrl()}/groups`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state, 'group'),
              userSelectors.getToken(state),
            ),
          },
          method: 'GET',
        })
      ));
      return { groups };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

interface ISendInvite {
  gid: number;
  email: string;
  setEmail: (email: string) => void;
}

const sendInvite = createAsyncThunk(
  'group/sendInvite',
  async (
    { email, gid, setEmail }: ISendInvite,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(errorActions.resetErrorState());
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      await fetchHandler(`${appSelectors.getUrl()}/groups/${gid}/invites`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            userSelectors.getToken(state),
          ),
        },
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      });
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(appActions.setCacheState({ key: 'group' }));
      setEmail('');
      return null;
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

interface IRemoveUser {
  gid: number;
  uid: number;
}

const removeUser = createAsyncThunk(
  'group/removeUser',
  async (
    { gid, uid }: IRemoveUser,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      await fetchHandler(`${appSelectors.getUrl()}/groups/${gid}/users`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            userSelectors.getToken(state),
          ),
        },
        method: 'DELETE',
        body: JSON.stringify({
          uid,
        }),
      });
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(appActions.setCacheState({ key: 'group' }));
      return {
        gid,
        uid,
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setCodeFromStorageState(state, action) {
      state.code = action.payload.code;
    },
    resetState(state) {
      state.groups = initialState.groups;
      state.code = initialState.code;
    },
  },
  extraReducers: builder => {
    builder.addCase(activateGroup.fulfilled, (state, action) => {
      if (action.payload?.gid) {
        state.groups = state.groups.map(group => {
          if (group.gid === action.payload.gid) {
            return {
              ...group,
              selected: true,
            };
          }
          return {
            ...group,
            selected: false,
          };
        });
      }
    });
    builder.addCase(joinGroup.fulfilled, (state, action) => {
      if (action.payload?.group) {
        state.groups = state.groups.concat(action.payload.group);
      }
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      if (action.payload?.group) {
        state.groups = state.groups.concat(action.payload.group);
      }
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      state.groups = state.groups.filter(
        group => group.gid !== action.payload.gid,
      );
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      state.groups = state.groups.map(group =>
        group.gid === action.payload.gid
          ? {
              ...group,
              users: group.users.filter(user => user.id !== action.payload.uid),
            }
          : group,
      );
    });
    builder.addCase(refreshGroups.fulfilled, (state, action) => {
      state.groups = action.payload.groups;
    });
    builder.addMatcher(shouldClearGroup, () => {
      return initialState;
    });
    builder.addMatcher(shouldRemoveGroup, (state, action) => {
      state.groups = state.groups.filter(
        group => group.gid !== action.payload.gid,
      );
    });
  },
});

export const actions = {
  ...groupSlice.actions,
  activateGroup,
  joinGroup,
  createGroup,
  deleteGroup,
  sendInvite,
  removeUser,
  refreshGroups,
};

export default groupSlice.reducer;
