export const colorsLight = {
  gray: {
    50: '#FAFAFA',
    100: '#F4F4F5',
    200: '#E4E4E7',
    300: '#D4D4D8',
    400: '#A1A1AA',
    500: '#71717A',
    600: '#52525B',
    700: '#3F3F46',
    800: '#27272A',
    900: '#18181B',
  },
  green: {
    700: '#065f46',
  },
  blue: {
    700: '#1d4ed8',
  },
  red: {
    700: '#b91c1c',
  },
};

export const colorsDark = {
  gray: {
    900: '#FAFAFA',
    800: '#F4F4F5',
    700: '#E4E4E7',
    600: '#D4D4D8',
    500: '#A1A1AA',
    400: '#71717A',
    300: '#52525B',
    200: '#3F3F46',
    100: '#27272A',
    50: '#18181B',
  },
  green: {
    700: '#059669',
  },
  blue: {
    700: '#3b82f6',
  },
  red: {
    700: '#ef4444',
  },
};

const tabBarLightStyle = {
  activeColor: colorsLight.gray[900],
  inactiveColor: colorsLight.gray[500],
  barStyle: {
    backgroundColor: colorsLight.gray[100],
    shadowOpacity: 0,
    borderTopWidth: 1,
    borderTopColor: colorsLight.gray[300],
  },
};

const tabBarDarkStyle = {
  activeColor: colorsDark.gray[900],
  inactiveColor: colorsDark.gray[500],
  barStyle: {
    backgroundColor: colorsDark.gray[100],
    shadowOpacity: 0,
    borderTopWidth: 1,
    borderTopColor: colorsDark.gray[300],
  },
};

const headerLightStyles = {
  headerTintColor: colorsLight.gray[900],
  headerStyle: {
    backgroundColor: colorsLight.gray[100],
    borderBottomWidth: 1,
    borderBottomColor: colorsLight.gray[300],
  },
  titleStyle: {
    fontFamily: 'FjallaOne-Regular',
    fontSize: 20,
  },
};

const headerDarkStyles = {
  headerTintColor: colorsDark.gray[900],
  headerStyle: {
    backgroundColor: colorsDark.gray[100],
    borderBottomWidth: 1,
    borderBottomColor: colorsDark.gray[300],
  },
  titleStyle: {
    fontFamily: 'FjallaOne-Regular',
    fontSize: 20,
  },
};

const topBarLightStyle = {
  indicatorStyle: { backgroundColor: colorsLight.gray[900] },
  labelStyle: {
    fontFamily: 'FjallaOne-Regular',
    fontSize: 14,
    marginBottom: 7,
  },
  inactiveTintColor: colorsLight.gray[500],
  activeTintColor: colorsLight.gray[900],
  barItemStyle: {
    maxHeight: 50,
    borderBottomWidth: 1,
    borderBottomColor: colorsLight.gray[300],
  },
  barStyle: {
    shadowOpacity: 0,
    elevation: 0,
    maxHeight: 50,
    backgroundColor: colorsLight.gray[50],
  },
};

const topBarDarkStyle = {
  indicatorStyle: { backgroundColor: colorsLight.gray[900] },
  labelStyle: {
    fontFamily: 'FjallaOne-Regular',
    fontSize: 14,
    marginBottom: 7,
  },
  inactiveTintColor: colorsDark.gray[500],
  activeTintColor: colorsDark.gray[900],
  barItemStyle: {
    maxHeight: 50,
    borderBottomWidth: 1,
    borderBottomColor: colorsDark.gray[300],
  },
  barStyle: {
    shadowOpacity: 0,
    elevation: 0,
    maxHeight: 50,
    backgroundColor: colorsDark.gray[50],
  },
};

export const topBarStyle = (isDark: boolean) => {
  return isDark ? topBarDarkStyle : topBarLightStyle;
};

export const tabBarStyle = (isDark: boolean) => {
  return isDark ? tabBarDarkStyle : tabBarLightStyle;
};

export const headerStyles = (isDark: boolean) => {
  return isDark ? headerDarkStyles : headerLightStyles;
};

const COLORS = {
  dark: colorsDark,
  light: colorsLight,
};

export const getColors = (theme: string) => {
  return COLORS[theme as 'light' | 'dark'];
};
