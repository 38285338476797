import { IRootState } from '@football/types/app';

export const getScores = (state: IRootState) => state.score.scores;

export const getPersonScores = (state: IRootState) => state.score.public.person;

export const getCountryScores = (state: IRootState) =>
  state.score.public.country;

export const getGroupScores = (state: IRootState) => state.score.public.group;

export const getLoader = (state: IRootState) => state.score.loader;

export const getFilter = (state: IRootState) => state.score.filter;
