import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';

import { Wrapper, Button, Spacing, Paragraph } from '../components';
import { useAppDispatch, useAppSelector } from '@football/app-utils';
import {
  actions as scoreActions,
  selectors as scoreSelectors,
} from '@football/modules/score';
import { i18n } from '@football/localization';

interface IProps {
  onCloseModal: () => void;
}

const FilterAction = (props: IProps) => {
  const theme = useTheme();
  const { colors } = theme;
  const dispatch = useAppDispatch();
  const filter = useAppSelector(scoreSelectors.getFilter);

  return (
    <Spacing mb={20}>
      <Wrapper borderBottom>
        <Spacing mb={15}>
          <Wrapper centerContent row>
            <Spacing ml={10} mr={10}>
              <Button
                icon
                testID="filterActionButtonPerson"
                disableLoader
                onPress={() => {
                  dispatch(scoreActions.setFilterState({ filter: 'person' }));
                  props.onCloseModal();
                }}>
                <Wrapper centerItems row grow>
                  <Spacing mr={5}>
                    <Icon
                      accessible={false}
                      importantForAccessibility="no"
                      accessibilityElementsHidden={true}
                      name="account"
                      size={30}
                      color={
                        filter === 'person'
                          ? colors.gray[900]
                          : colors.gray[500]
                      }
                    />
                  </Spacing>
                  <Paragraph>
                    {i18n.translate('score.filterAction.person')}
                  </Paragraph>
                </Wrapper>
              </Button>
            </Spacing>
            <Spacing ml={10} mr={10}>
              <Button
                icon
                testID="filterActionButtonGroup"
                disableLoader
                onPress={() => {
                  dispatch(scoreActions.setFilterState({ filter: 'group' }));
                  props.onCloseModal();
                }}>
                <Wrapper row centerItems grow>
                  <Spacing mr={5}>
                    <Icon
                      accessible={false}
                      importantForAccessibility="no"
                      accessibilityElementsHidden={true}
                      name="account-group"
                      size={30}
                      color={
                        filter === 'group' ? colors.gray[900] : colors.gray[500]
                      }
                    />
                  </Spacing>
                  <Paragraph>
                    {i18n.translate('score.filterAction.group')}
                  </Paragraph>
                </Wrapper>
              </Button>
            </Spacing>
            <Spacing ml={10} mr={10}>
              <Button
                icon
                testID="filterActionButtonCountry"
                disableLoader
                onPress={() => {
                  dispatch(scoreActions.setFilterState({ filter: 'country' }));
                  props.onCloseModal();
                }}>
                <Wrapper row centerItems grow>
                  <Spacing mr={5}>
                    <Icon
                      accessible={false}
                      importantForAccessibility="no"
                      accessibilityElementsHidden={true}
                      name="earth"
                      size={30}
                      color={
                        filter === 'country'
                          ? colors.gray[900]
                          : colors.gray[500]
                      }
                    />
                  </Spacing>
                  <Paragraph>
                    {i18n.translate('score.filterAction.country')}
                  </Paragraph>
                </Wrapper>
              </Button>
            </Spacing>
          </Wrapper>
        </Spacing>
      </Wrapper>
    </Spacing>
  );
};

export default FilterAction;
