import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  IDeviceInfo,
  IAppDispatch,
  IRootState,
  IPlatform,
} from '@football/types/app';

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

export const useAppDispatch: () => IAppDispatch = useDispatch;

export const getLocalizedError = (
  t: (s: string) => string,
  error: {
    message?: string;
    name?: string;
    userInfo?: {
      code: string;
    };
  },
) => {
  if (error.userInfo?.code) {
    return t(`error.${error.userInfo.code}`);
  } else if (error.message === 'ERROR_RESTORE_PURCHASE') {
    return t(`error.${error.message}`);
  }
  return error.message;
};

export const getStatusBarHeightByPlatForm = (
  Platform: IPlatform,
  DeviceInfo: IDeviceInfo,
  android: number,
  iphone: number,
) => {
  return Platform.select({
    ios: DeviceInfo.hasNotch() ? 44 : iphone,
    android,
  });
};
