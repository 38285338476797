import React from 'react';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import styled, { css } from 'styled-components/native';

import { getStatusBarHeightByPlatForm } from '@football/app-utils';

interface IBaseProps {
  modal?: boolean;
  padded?: boolean;
  borderBottom?: boolean;
  centerContent?: boolean;
  flexible?: boolean;
  row?: boolean;
  borderTop?: boolean;
  centerItems?: boolean;
  transparent?: boolean;
  safe?: boolean;
  popup?: boolean;
  rounded?: boolean;
  centered?: boolean;
  statusBar?: boolean;
  light?: boolean;
  dark?: boolean;
  card?: boolean;
  bottom?: boolean;
  betweenContent?: boolean;
  wrap?: boolean;
  small?: boolean;
  paddingTop?: boolean;
  shaded?: boolean;
  startItems?: boolean;
  noBorderRight?: boolean;
  grow?: boolean;
}
interface IProps extends IBaseProps {
  children?: React.ReactNode;
}

const betweenContent = css`
  justify-content: space-between;
`;

const centerContent = css`
  justify-content: center;
`;

const modal = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

const padded = css`
  padding: 20px 10px;
`;

const small = css`
  padding: 10px 5px;
`;

const card = css`
  padding: 15px 10px;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray[300]};
  box-shadow: ${props =>
    props.theme.isDark
      ? 'none'
      : `-1px 1px 1px ${props.theme.colors.gray[200]}`};
`;

const noBorderRight = css`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right-width: 0;
`;

const bottom = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: ${Platform.OS === 'ios' && DeviceInfo.hasNotch()
    ? '50px'
    : '20px'};
`;

const paddingTop = css`
  padding-top: ${Platform.OS === 'ios' && DeviceInfo.hasNotch()
    ? '57px'
    : '27px'};
`;

const flex = css`
  flex: 1;
`;

const centerItems = css`
  align-items: center;
`;

const startItems = css`
  align-items: flex-start;
`;

const row = css`
  flex-direction: row;
`;

const popup = css`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const shaded = css`
  background-color: rgba(0, 0, 0, 0.5);
`;

const rounded = css`
  border-radius: 10px;
`;

const centered = css`
  position: absolute;
  top: 25%;
  bottom: 25%;
  right: 0;
  left: 0;
`;

const height = getStatusBarHeightByPlatForm(Platform, DeviceInfo, 30, 30);

const statusBar = css`
  position: ${Platform.OS === 'ios' && DeviceInfo.hasNotch()
    ? 'absolute'
    : 'relative'};
  background-color: ${props => props.theme.colors.red[700]};
  top: 0;
  right: 0;
  left: 0;
  height: ${height}px;
`;

const borderBottom = css<IBaseProps>`
  border-bottom-width: 1px;
  border-bottom-color: ${props =>
    props.light ? props.theme.colors.gray[200] : props.theme.colors.gray[300]};
`;

const borderTop = css<IBaseProps>`
  border-top-width: 1px;
  border-top-color: ${props =>
    props.light ? props.theme.colors.gray[200] : props.theme.colors.gray[300]};
`;

const background = css`
  background-color: ${props => props.theme.colors.gray[50]};
`;

const dark = css`
  background-color: ${props => props.theme.colors.gray[100]};
`;

const wrap = css`
  flex-wrap: wrap;
`;

const grow = css`
  flex-grow: 1;
`;

const StyledView = styled.View<IBaseProps>`
  ${props => !props.transparent && background}
  ${props => props.flexible && flex}
  ${props => props.centerContent && centerContent}
  ${props => props.borderBottom && borderBottom}
  ${props => props.padded && padded}
  ${props => props.modal && modal}
  ${props => props.centerItems && centerItems}
  ${props => props.startItems && startItems}
  ${props => props.borderTop && borderTop}
  ${props => props.row && row}
  ${props => props.popup && popup}
  ${props => props.rounded && rounded}
  ${props => props.centered && centered}
  ${props => props.statusBar && statusBar}
  ${props => props.bottom && bottom}
  ${props => props.dark && dark}
  ${props => props.card && card}
  ${props => props.betweenContent && betweenContent}
  ${props => props.wrap && wrap}
  ${props => props.shaded && shaded}
  ${props => props.paddingTop && paddingTop}
  ${props => props.padded && props.small && small}
  ${props => props.noBorderRight && noBorderRight}
  ${props => props.grow && grow}
`;

const StyledSafeAreaView = styled.SafeAreaView<IBaseProps>`
  flex: 1;
  ${props => !props.transparent && background}
`;

const Wrapper = (props: IProps) => {
  if (props.safe) {
    return <StyledSafeAreaView {...props}>{props.children}</StyledSafeAreaView>;
  }
  return <StyledView {...props}>{props.children}</StyledView>;
};

export default Wrapper;
