import React from 'react';
import { createGlobalStyle, css } from 'styled-components';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';

export const fontStyles = css`
  @font-face {
    font-family: 'FjallaOne-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/fjallaone/v8/Yq6R-LCAWCX3-6Ky7FAFrOF6kjouQb4.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  @font-face {
    font-family: 'Raleway-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEorCIPrcVIT9d0c8.woff)
      format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  @font-face {
    font-family: 'Raleway-LightItalic';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMDrMfJh1Zyc61YA.woff)
      format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  @font-face {
    font-family: 'Raleway-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  @font-face {
    font-family: 'Raleway-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pbCIPrcVIT9d0c8.woff)
      format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${fontStyles}
  ${`@font-face {
    src: url(${MaterialCommunityIcons});
    font-family: MaterialCommunityIcons;
  }`}
  html,
  body {
    background-color: #fff;
  }
  #root {
    min-height: 100vh;
    display: flex;
    max-width: 1024px;
    margin: 0 auto;
  }
`;

const GlobalStyleDecorator = createGlobalStyle`
  ${fontStyles}
  ${`@font-face {
    src: url(${MaterialCommunityIcons});
    font-family: MaterialCommunityIcons;
    font-display: swap;
  }`}
`;

export const globalStyleDecorator = (story: () => void) => {
  return (
    <>
      <GlobalStyleDecorator />
      {story()}
    </>
  );
};

export default () => {
  return <GlobalStyle />;
};
