import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Platform } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';
import DeviceInfo from 'react-native-device-info';

import {
  actions as errorActions,
  selectors as errorSelectors,
} from '@football/modules/error';
import {
  selectors as loaderSelectors,
  actions as loaderActions,
} from '@football/modules/loader';

import {
  getStatusBarHeightByPlatForm,
  useAppDispatch,
  useAppSelector,
} from '@football/app-utils';

interface IBaseProps {
  secondary?: boolean;
  primary?: boolean;
  link?: boolean;
  cancel?: boolean;
  custom?: boolean;
  transparent?: boolean;
  small?: boolean;
  height?: number;
  full?: boolean;
  itemsEnd?: boolean;
  icon?: boolean;
  disabled?: boolean;
  flexible?: boolean;
  inverted?: boolean;
  width?: number;
  light?: boolean;
}

interface IProps extends IBaseProps {
  children?: React.ReactNode;
  activeOpacity?: number;
  disableLoader?: boolean;
  validate?: boolean;
  dontResetError?: boolean;
  text?: string;
  onPress: () => void;
  testID: string;
  loaderColor?: string;
  style?: object;
}

const base = css`
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 50px;
  font-family: Raleway-Bold;
`;

const icon = css`
  min-width: 50px;
  min-height: 50px;
  align-items: center;
  justify-content: center;
`;

const full = css`
  width: 100%;
`;

const flex = css`
  flex: 1;
`;

const itemsEnd = css`
  align-items: flex-end;
`;

const primary = css`
  ${base}
  background.color: ${props => props.theme.colors.green[700]};
  color: ${props => props.theme.colors.gray[50]};
`;

const custom = css`
  ${base}
  color:${props => props.theme.colors.gray[50]};
`;

const transparent = css`
  ${base}
  background-color: transparent;
`;

const secondary = css`
  ${base}
  background-color: ${props => props.theme.colors.gray[700]};
  color: ${props => props.theme.colors.gray[50]};
`;

const light = css`
  ${base}
  background-color: ${props => props.theme.colors.gray[300]};
  color: ${props => props.theme.colors.gray[700]};
`;

const cancel = css<IBaseProps>`
  ${base}
  background-color: ${props =>
    props.inverted ? props.theme.colors.gray[50] : props.theme.colors.red[700]};
  color: ${props =>
    props.inverted ? props.theme.colors.red[700] : props.theme.colors.gray[50]};
`;

const link = css`
  ${base}
  background-color: ${props => props.theme.colors.gray[50]};
  color: ${props => props.theme.colors.blue[700]};
`;

const small = css`
  height: ${getStatusBarHeightByPlatForm(Platform, DeviceInfo, 30, 30)}px;
`;

const disabled = css`
  opacity: 0.5;
`;

const StyledButton = styled.TouchableOpacity<IBaseProps>`
  ${props => props.primary && primary}
  ${props => props.secondary && secondary}
  ${props => props.link && link}
  ${props => props.cancel && cancel}
  ${props => props.custom && custom}
  ${props => props.light && light}
  ${props => props.transparent && transparent}
  ${props => props.small && small}
  ${props => props.height && `height: ${props.height}px;`}
  ${props => props.itemsEnd && itemsEnd}
  ${props => props.full && full}
  ${props => props.icon && icon}
  ${props => props.flexible && flex}
  ${props => props.width && `width: ${props.width}px;`}
`;

const StyledText = styled.Text<IBaseProps>`
  text-transform: capitalize;
  flex: 1;
  flex-wrap: wrap;
  ${props => props.primary && primary}
  ${props => props.secondary && secondary}
  ${props => props.link && link}
  ${props => props.cancel && cancel}
  ${props => props.custom && custom}
  ${props => props.light && light}
  ${props => props.transparent && transparent}
  ${props => props.disabled && disabled}
  width: 100%;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  overflow: hidden;
  font-size: 14px;
  ${props => props.height && `line-height: ${props.height}px;`}
`;

const Button = (props: IProps) => {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const { colors } = theme;

  const [active, setActive] = useState<boolean>(false);

  const loader = useAppSelector(loaderSelectors.getLocalLoader);
  const error = useAppSelector(errorSelectors.getError);

  useEffect(() => {
    if (!loader && active) {
      setActive(false);
    }
  }, [active, loader]);

  useEffect(() => {
    return () => {
      setActive(false);
    };
  }, []);

  const onPress = () => {
    if (error.message && !props.dontResetError) {
      dispatch(errorActions.resetErrorState());
    }
    if (!props.disableLoader && !props.validate) {
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
    }
    setActive(true);
    props.onPress();
  };

  if (props.children) {
    return (
      <StyledButton
        {...props}
        accessibilityRole="button"
        accessibilityLabel={props.testID}
        onPress={loader && props.disabled ? () => null : onPress}>
        {active && loader && !props.disableLoader ? (
          <ActivityIndicator
            size="small"
            accessible={false}
            importantForAccessibility="no"
            accessibilityElementsHidden={true}
            color={props.loaderColor ? props.loaderColor : colors.gray[50]}
          />
        ) : (
          props.children
        )}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      {...props}
      accessibilityRole="button"
      accessibilityLabel={props.testID ? props.testID : props.text}
      onPress={loader && props.disabled ? () => null : onPress}>
      {active && loader && !props.disableLoader ? (
        <ActivityIndicator
          size="small"
          accessible={false}
          importantForAccessibility="no"
          accessibilityElementsHidden={true}
          color={props.loaderColor ? props.loaderColor : colors.gray[50]}
        />
      ) : (
        <StyledText
          disabled={props.disabled}
          height={props.height}
          primary={props.primary}
          secondary={props.secondary}
          custom={props.custom}
          cancel={props.cancel}
          transparent={props.transparent}
          link={props.link}
          light={props.light}
          inverted={props.inverted}>
          {props.text}
        </StyledText>
      )}
    </StyledButton>
  );
};

export default Button;
