import React, { useCallback } from 'react';
import { Linking, Platform, Alert } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTheme } from 'styled-components/native';
import Clipboard from '@react-native-clipboard/clipboard';

import { Wrapper, Button, Spacing, Paragraph, Header } from '../components';
import { selectors as appSelectors } from '@football/modules/app';
import { i18n } from '@football/localization';
import { actions as loaderActions } from '@football/modules/loader';
import {
  actions as groupActions,
  types as groupTypes,
} from '@football/modules/group';
import {
  selectors as userSelectors,
  actions as userActions,
} from '@football/modules/user';
import { useAppDispatch, useAppSelector } from '@football/app-utils';

type INavigationParamList = {
  Group?: {
    group?: groupTypes.IGroup;
  };
  TabNavigator?: Record<string, never>;
};

interface IProps {
  group: groupTypes.IGroup;
  navigation?: NativeStackNavigationProp<INavigationParamList>;
}

const GroupAction = (props: IProps) => {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const { colors } = theme;

  const urlScheme = useAppSelector(appSelectors.getUrlScheme);
  const group = useAppSelector(userSelectors.getGroup);
  const unlockInvites = useAppSelector(userSelectors.getUnlockInvites);
  const features = useAppSelector(appSelectors.getFeatures);
  const store = useAppSelector(appSelectors.getStore);

  const checkUsers = useCallback(
    (item: groupTypes.IGroup) => {
      return item.users.length >= 5 && !unlockInvites;
    },
    [unlockInvites],
  );

  const fallBackCode = props.group.users[0]?.group?.code || '';

  const openUrl = async () => {
    if (checkUsers(props.group)) {
      return props.navigation?.navigate('Group', { group: props.group });
    }

    const bodyIos = i18n.translate('join.message', {
      store: store.ios,
      urlScheme,
      code: props.group.code || fallBackCode,
    });

    const bodyAndroid = i18n.translate('join.message', {
      store: store.android,
      urlScheme,
      code: props.group.code || fallBackCode,
    });

    await Linking.openURL(
      `sms:${Platform.OS === 'ios' ? '&' : '?'}body=${encodeURIComponent(
        `Apple\n${bodyIos}\n\nAndroid\n${bodyAndroid}`,
      )}`,
    );
  };

  const copytoClipBoard = () => {
    Clipboard.setString(props.group.code || fallBackCode);
  };

  const deleteGroup = () => {
    if (props.group.gid === group?.gid) {
      return null;
    }
    Alert.alert(i18n.translate('alert.title'), '', [
      {
        text: i18n.translate('alert.buttonNo'),
        onPress: () =>
          dispatch(loaderActions.setLocalLoaderState({ loader: false })),
      },
      {
        text: i18n.translate('alert.buttonYes'),
        onPress: async () => {
          await dispatch(groupActions.deleteGroup(props.group.gid));
          props.navigation?.navigate('TabNavigator');
        },
      },
    ]);
  };

  const removeGroup = () => {
    if (props.group.gid === group?.gid) {
      return null;
    }
    Alert.alert(i18n.translate('alert.title'), '', [
      {
        text: i18n.translate('alert.buttonNo'),
        onPress: () =>
          dispatch(loaderActions.setLocalLoaderState({ loader: false })),
      },
      {
        text: i18n.translate('alert.buttonYes'),
        onPress: async () => {
          await dispatch(userActions.removeGroup(props.group.gid));
          props.navigation?.navigate('TabNavigator');
        },
      },
    ]);
  };

  return (
    <Spacing mb={20}>
      <Spacing mb={20}>
        <Header>{i18n.translate('group.groupAction.title')}</Header>
      </Spacing>
      <Wrapper borderBottom>
        <Spacing mb={15}>
          <Wrapper row centerItems>
            <Wrapper flexible row>
              {props.group.isOwner && (
                <Spacing mr={10}>
                  <Button
                    icon
                    testID="groupActionButtonNavigate"
                    disableLoader
                    onPress={() => {
                      props.navigation?.navigate('Group', {
                        group: props.group,
                      });
                    }}>
                    <Wrapper row centerItems grow>
                      <Spacing mr={5}>
                        <Icon
                          accessible={false}
                          importantForAccessibility="no"
                          accessibilityElementsHidden={true}
                          name={
                            checkUsers(props.group) ? 'email-lock' : 'email'
                          }
                          size={30}
                          color={colors.gray[700]}
                        />
                      </Spacing>
                      <Paragraph>
                        {i18n.translate('group.groupAction.buttonEmail')}
                      </Paragraph>
                    </Wrapper>
                  </Button>
                </Spacing>
              )}
              <Spacing mr={10} ml={10}>
                <Button
                  icon
                  testID="groupActionButtonOpenUrl"
                  disableLoader
                  onPress={openUrl}>
                  <Wrapper row centerItems grow>
                    <Spacing mr={5}>
                      <Icon
                        accessible={false}
                        importantForAccessibility="no"
                        accessibilityElementsHidden={true}
                        name={
                          checkUsers(props.group)
                            ? 'message-text-lock'
                            : 'message-text'
                        }
                        size={30}
                        color={colors.gray[700]}
                      />
                    </Spacing>
                    <Paragraph>
                      {i18n.translate('group.groupAction.buttonSms')}
                    </Paragraph>
                  </Wrapper>
                </Button>
              </Spacing>
              <Spacing mr={10} ml={10}>
                <Button
                  icon
                  testID="groupActionButtonCopy"
                  disableLoader
                  onPress={copytoClipBoard}>
                  <Wrapper row centerItems grow>
                    <Spacing mr={5}>
                      <Icon
                        accessible={false}
                        importantForAccessibility="no"
                        accessibilityElementsHidden={true}
                        name={checkUsers(props.group) ? 'lock' : 'content-copy'}
                        size={30}
                        color={colors.gray[700]}
                      />
                    </Spacing>
                    <Paragraph>{props.group.code || fallBackCode}</Paragraph>
                  </Wrapper>
                </Button>
              </Spacing>
            </Wrapper>
            {props.group.gid !== group?.gid && (
              <Button
                icon
                loaderColor={colors.gray[700]}
                disabled={!features.group}
                testID="groupActionButtonDelete"
                onPress={props.group.isOwner ? deleteGroup : removeGroup}>
                <Wrapper row centerItems grow>
                  <Spacing mr={5}>
                    <Icon
                      accessible={false}
                      importantForAccessibility="no"
                      accessibilityElementsHidden={true}
                      name="trash-can"
                      size={25}
                      color={colors.red[700]}
                    />
                  </Spacing>
                  <Paragraph>
                    {i18n.translate('group.groupAction.buttonDelete')}
                  </Paragraph>
                </Wrapper>
              </Button>
            )}
          </Wrapper>
        </Spacing>
      </Wrapper>
    </Spacing>
  );
};

export default GroupAction;
