import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PurchaseError } from 'react-native-iap';
import { IState, IItemSkus, IPurchase } from './types';
import { IRootState, IExtraArgument } from '@football/types/app';
import { selectors as appSelectors } from '../app/';
import { selectors as userSelectors, actions as userActions } from '../user';
import { selectors as productSelectors } from './';
import { actions as errorActions } from '../error';
import { fetchHandler, getHeaders } from '@football/api';
import { actions as loaderActions } from '../loader';

const getCurrentProduct = (bundleIdentifier: string, type: string) => {
  let defaultBundleIdentifier = bundleIdentifier;
  if (Platform.OS === 'android' && bundleIdentifier === 'io.srvc.soccer.sa') {
    defaultBundleIdentifier = 'io.srvc.soccer.seriea';
  }
  return {
    name: `Unlock ${type}`,
    productId: `${
      Platform.OS === 'ios' ? 'ios.' : ''
    }${defaultBundleIdentifier}.unlock.${type}`,
  };
};

const initialState: IState = {
  product: null,
  itemSkus: <IItemSkus>Platform.select({
    ios: [
      `ios.${DeviceInfo.getBundleId()}.unlock.invites`,
      `ios.${DeviceInfo.getBundleId()}.unlock.groups`,
    ],
    android: [
      `${DeviceInfo.getBundleId()}.unlock.invites`,
      `${DeviceInfo.getBundleId()}.unlock.groups`,
    ],
  }),
};

const handleError = createAsyncThunk(
  'product/handleError',
  (error: PurchaseError, { extra, dispatch, getState }) => {
    const { Sentry } = extra as IExtraArgument;
    const state = getState() as IRootState;
    const currentUser = userSelectors.getUser(state);
    if (currentUser) {
      Sentry.setUser({
        email: currentUser.email,
        id: currentUser.id,
      });
    }
    console.error(error);
    Sentry.captureException(error);
    if (
      error instanceof PurchaseError &&
      error?.code !== 'E_USER_CANCELLED' &&
      error?.code !== 'E_UNKNOWN' &&
      error?.code !== 'E_ALREADY_OWNED'
    ) {
      dispatch(errorActions.setError(error));
    }
    dispatch(loaderActions.setLocalLoaderState({ loader: false }));
  },
);

const acceptProduct = createAsyncThunk(
  'product/acceptProduct',
  async (purchase: IPurchase, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as IRootState;
    try {
      const product = productSelectors.getProduct(state);
      await fetchHandler(`${appSelectors.getUrl()}/products`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            userSelectors.getToken(state),
          ),
        },
        method: 'POST',
        body: JSON.stringify({
          product,
          purchase,
        }),
      });
      await dispatch(userActions.getUser());
      dispatch(productSlice.actions.resetProductState());
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue(error);
    }
  },
);

const restoreProduct = createAsyncThunk(
  'product/restoreProduct',
  async (purchase: IPurchase, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as IRootState;
    try {
      const product = productSelectors.getProduct(state);
      await fetchHandler(`${appSelectors.getUrl()}/products`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            userSelectors.getToken(state),
          ),
        },
        method: 'POST',
        body: JSON.stringify({
          product,
          purchase,
        }),
      });
      await dispatch(userActions.getUser());
      dispatch(productSlice.actions.resetProductState());
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue(error);
    }
  },
);

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    unLockGroupState(state) {
      state.product = getCurrentProduct(DeviceInfo.getBundleId(), 'groups');
    },
    unLockInviteState(state) {
      state.product = getCurrentProduct(DeviceInfo.getBundleId(), 'invites');
    },
    resetProductState(state) {
      state.product = initialState.product;
    },
  },
});

export const actions = {
  ...productSlice.actions,
  handleError,
  acceptProduct,
  restoreProduct,
};

export default productSlice.reducer;
