import React from 'react';
import styled from 'styled-components/native';

import { Header } from './';

interface IBaseProps {
  size?: string;
}

interface IProps extends IBaseProps {
  label?: string;
  picture?: string;
}

const size = '30px';

const StyledView = styled.View<IBaseProps>`
  width: ${props => (props.size ? props.size : size)};
  height: ${props => (props.size ? props.size : size)};
  background-color: ${props => props.theme.colors.gray[300]};
  align-items: center;
  justify-content: center;
  border-radius: ${props => (props.size ? props.size : size)};
`;

const StyledImage = styled.Image<IBaseProps>`
  width: ${props => (props.size ? props.size : size)};
  height: ${props => (props.size ? props.size : size)};
  border-radius: ${props => (props.size ? props.size : size)};
`;

const Image = (props: IProps) => {
  if (props.picture) {
    return (
      <StyledImage
        {...props}
        accessible={false}
        importantForAccessibility="no"
        accessibilityElementsHidden={true}
        source={{
          uri: props.picture,
        }}
      />
    );
  }
  return (
    <StyledView {...props}>
      <Header subtitle>{props.label}</Header>
    </StyledView>
  );
};

export default Image;
