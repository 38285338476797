import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '@football/types/app';

export const getLoggedIn = (state: IRootState) => state.user.isLoggedIn;

export const getUser = (state: IRootState) => state.user.user;

export const getToken = (state: IRootState) => state.user.user?.token;

export const isRegister = (state: IRootState) => state.user.register;

export const getUnlockInvites = (state: IRootState) =>
  state.user.user?.unlockInvites;

export const getUnlockGroups = (state: IRootState) =>
  state.user.user?.unlockGroups;

export const getId = (state: IRootState) => state.user.user?.id;

const getGroups = (state: IRootState) => state.group.groups;

export const getActiveGroups = createSelector(
  getUser,
  getGroups,
  (user, groups) => {
    if (user && groups.length) {
      return groups.filter(
        group => user.group.gids && user.group.gids.includes(group.gid),
      );
    }
    return [];
  },
);

export const getGroup = createSelector(getUser, getGroups, (user, groups) => {
  if (user && groups.length) {
    return groups.filter(group => group.gid === user.group.gid)[0];
  }
  return null;
});
