import * as Sentry from '@sentry/react';

type IOriginalException = {
  resource: string;
  type: string;
  status: string;
  message: string;
} | null;

const DeviceInfo = {
  getBundleId: () => {
    return 'io.srvc.soccer.fifa';
  },
  getVersion: () => {
    return process.env.REACT_APP_VERSION;
  },
  getBuildNumber: () => {
    return process.env.REACT_APP_COMMIT;
  },
  getUniqueIdSync: () => {
    return navigator.userAgent;
  },
};

const Platform = {};

if (process.env.NODE_ENV !== 'test') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    release: DeviceInfo.getVersion(),
    dsn: 'https://be36acbfa337ee30f81e798607ce9bd9@o43145.ingest.sentry.io/4506710550118400',
    dist: DeviceInfo.getBuildNumber(),
    tracesSampleRate: 1,
    sampleRate: 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    beforeSend: function (event, hint) {
      const exception = hint.originalException as IOriginalException;
      if (exception?.type === 'FetchError') {
        event.transaction = String(exception.resource);
        event.fingerprint = [
          String(exception.resource),
          String(exception.status),
          String(exception.message),
        ];
      }
      return event;
    },
  });
}

Sentry.setTag('X-Unique-Id', DeviceInfo.getUniqueIdSync());
Sentry.setTag('X-Bundle-Id', DeviceInfo.getBundleId());

const thunk = {
  extraArgument: {
    DeviceInfo,
    Platform,
  },
};

export default thunk;
