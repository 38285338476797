import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '@football/types/app';
import { IPlayer } from './types';

const getPlayers = (state: IRootState) => state.player.players;

export const getSelectedPlayer = (state: IRootState) =>
  state.player.selectedPlayer;

export const getQuery = (state: IRootState) => state.player.query;

const getSearch = (state: IRootState) => state.app.config.search;

const searchBy = (players: IPlayer[], activeQuery: string) => {
  const search = activeQuery.toLowerCase().split(' ');
  return players.filter(player => {
    const totalPlayers = search.filter(s => {
      return player.name.toLowerCase().search(s) !== -1;
    });
    return totalPlayers.length === search.length;
  });
};

export const getActiveQuery = createSelector(
  getQuery,
  getSearch,
  (query, search) => {
    if (query.length >= search) {
      return query;
    }
    return '';
  },
);

export const getFilteredPlayers = createSelector(
  getPlayers,
  getActiveQuery,
  (players, activeQuery) => {
    if (activeQuery) {
      return searchBy(players, activeQuery);
    }
    return players;
  },
);
