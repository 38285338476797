import React from 'react';
import { Provider } from 'react-redux';
import { AppRegistry } from 'react-native';

import GlobalStyles from '@football/components/components/GlobalStyles';
import { store } from './config/store';
import App from './App';

class Root extends React.Component {
  render() {
    return (
      <>
        <GlobalStyles />
        <Provider store={store}>
          <App />
        </Provider>
      </>
    );
  }
}

AppRegistry.registerComponent('Football', () => Root);

AppRegistry.runApplication('Football', {
  rootTag: document.getElementById('root'),
});
