import {
  createSlice,
  createAsyncThunk,
  unwrapResult,
  PayloadAction,
} from '@reduxjs/toolkit';

import { selectors as appSelectors, actions as appActions } from '../app/';
import { selectors as userSelectors } from './';
import { actions as errorActions } from '../error';
import { FetchError, fetchHandler, getHeaders } from '@football/api';
import { actions as loaderActions } from '../loader';
import {
  IRootState,
  IExtraArgument,
  IUserCredential,
} from '@football/types/app';
import { actions as standingActions } from '../standing';
import { actions as scoreActions } from '../score';
import { actions as notificationActions } from '../notification';
import { actions as groupActions, types as groupTypes } from '../group';
import { actions as matchActions } from '../match';
import { IState } from './types';
import { IContractUser } from '@football/types';

export const initialState: IState = {
  register: false,
  user: null,
  isLoggedIn: false,
};

function setActiveGroup(
  action: PayloadAction<{ type: string; gid: number }>,
): action is PayloadAction<{ type: string; gid: number }> {
  return action.type === 'group/active';
}

function deleteGroup(
  action: PayloadAction<{ type: string; gid: number }>,
): action is PayloadAction<{ type: string; gid: number }> {
  return action.type === 'group/deleteGroup/fulfilled';
}

function createOrJoinGroup(
  action: PayloadAction<{ type: string; group: groupTypes.IGroup }>,
): action is PayloadAction<{ type: string; group: groupTypes.IGroup }> {
  return (
    action.type === 'group/createGroup/fulfilled' ||
    action.type === 'group/joinGroup/fulfilled'
  );
}

const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (_, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, auth, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      await fetchHandler(
        `${appSelectors.getUrl()}/users/${userSelectors.getId(
          state,
        )}/?delete=true`,
        {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'DELETE',
        },
      );
      auth().signOut();
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, auth, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      await fetchHandler(
        `${appSelectors.getUrl()}/users/${userSelectors.getId(state)}`,
        {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'DELETE',
        },
      );
      auth().signOut();
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const getUser = createAsyncThunk(
  'user/getUser',
  async (_, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const user = await fetchHandler(
        `${appSelectors.getUrl()}/users/${userSelectors.getId(state)}`,
        {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'GET',
        },
      );
      dispatch(userSlice.actions.updateUserState({ user }));
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

type ILogin = { token: string; shouldRefresh: boolean };

const login = createAsyncThunk(
  'user/login',
  async (
    { token, shouldRefresh }: ILogin,
    { extra, dispatch, getState, rejectWithValue, fulfillWithValue },
  ) => {
    const { DeviceInfo, Notifications, getLocales, Platform } =
      extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const locales = getLocales();

      const { user } = await (<
        Promise<{
          user: IContractUser;
        }>
      >fetchHandler(`${appSelectors.getUrl()}/auth`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            token,
          ),
        },
        method: 'POST',
        body: JSON.stringify({
          language: locales[0].languageTag,
          bundleIdentifier: DeviceInfo.getBundleId(),
        }),
      }));

      dispatch(userSlice.actions.loginUserState({ user }));

      if (Platform.OS === 'ios') {
        Notifications.ios.setBadgeCount(0);
      }

      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(userSlice.actions.registerUserState({ register: false }));
      dispatch(
        appActions.setDisableBackgroundModeState({
          disableBackgroundMode: false,
        }),
      );

      dispatch(groupActions.refreshGroups());
      dispatch(notificationActions.refreshNotifications());

      if (!shouldRefresh) {
        dispatch(matchActions.refreshMatches(shouldRefresh));
        dispatch(scoreActions.refreshScores(shouldRefresh));
        dispatch(standingActions.refreshStandings(shouldRefresh));
      }

      return fulfillWithValue({ user });
    } catch (error) {
      const fetchError = error as FetchError;
      return rejectWithValue({ ...fetchError, message: fetchError.message });
    }
  },
);

const resetUserAuthentication = createAsyncThunk(
  'user/resetUserAuthentication',
  async (isBackgroundMode: boolean, { dispatch, getState }) => {
    const state = getState() as IRootState;
    const id = appSelectors.getId(state);

    if (!id) {
      return null;
    }

    if (!isBackgroundMode) {
      dispatch(groupActions.resetState());

      dispatch(userSlice.actions.logoutUserState());
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(loaderActions.setGlobalLoaderState({ loader: false }));
    }
  },
);

const refreshUserAuthentication = createAsyncThunk(
  'user/refreshUserAuthentication',
  async (_, { extra, rejectWithValue, dispatch, getState }) => {
    const { auth } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const id = appSelectors.getId(state);

      if (!id) {
        return null;
      }

      const token = await auth().currentUser?.getIdToken();

      if (token) {
        const res = await dispatch(login({ token, shouldRefresh: false }));
        unwrapResult(res);
      } else {
        dispatch(userSlice.actions.logoutUserState());
      }
    } catch (error) {
      dispatch(userSlice.actions.logoutUserState());
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

const checkUserAuthentication = createAsyncThunk(
  'user/checkUserAuthentication',
  async (_, { extra, rejectWithValue, dispatch, getState }) => {
    const { auth } = extra as IExtraArgument;
    const state = getState() as IRootState;
    const isRegister = userSelectors.isRegister(state);
    const id = appSelectors.getId(state);
    try {
      if (!id) {
        return null;
      }

      if (isRegister) {
        return null;
      }

      const token = await auth().currentUser?.getIdToken(true);

      if (token) {
        const res = await dispatch(login({ token, shouldRefresh: true }));
        unwrapResult(res);
      } else {
        dispatch(userSlice.actions.logoutUserState());
      }
      dispatch(loaderActions.setGlobalLoaderState({ loader: false }));
    } catch (error) {
      dispatch(userSlice.actions.logoutUserState());
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

interface ISaveUser {
  name: string;
}

const saveUser = createAsyncThunk(
  'user/saveUser',
  async (
    { name }: ISaveUser,
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      await fetchHandler(
        `${appSelectors.getUrl()}/users/${userSelectors.getId(state)}`,
        {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'PUT',
          body: JSON.stringify({
            name,
          }),
        },
      );
      dispatch(appActions.setCacheState({ key: 'match' }));
      dispatch(appActions.setCacheState({ key: 'score' }));
      dispatch(appActions.setTrigger('saveUser'));
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

interface ICreateUser {
  email?: string;
  name?: string;
  providerUser?: IUserCredential;
}

const createUser = createAsyncThunk(
  'user/createUser',
  async (
    { email, name, providerUser }: ICreateUser,
    { extra, dispatch, getState, rejectWithValue },
  ) => {
    const { DeviceInfo, getLocales, auth, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const locales = getLocales();
      const token = await auth().currentUser?.getIdToken();
      const { user } = await (<
        Promise<{
          user: IContractUser;
        }>
      >fetchHandler(`${appSelectors.getUrl()}/users`, {
        headers: {
          ...getHeaders(
            DeviceInfo,
            Platform,
            appSelectors.getCacheKey(state),
            token,
          ),
        },
        method: 'POST',
        body: JSON.stringify({
          email: providerUser ? providerUser.user.email : email,
          name: providerUser ? providerUser.user.displayName : name,
          language: locales[0].languageTag,
          aid: appSelectors.getId(state),
        }),
      }));
      dispatch(loaderActions.setGlobalLoaderState({ loader: false }));
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(
        appActions.setDisableBackgroundModeState({
          disableBackgroundMode: false,
        }),
      );
      dispatch(userSlice.actions.registerUserState({ register: false }));
      return { user };
    } catch (error) {
      const fetchError = error as FetchError;
      return rejectWithValue({ ...fetchError, message: fetchError.message });
    }
  },
);

interface IRegisterUser {
  email: string;
  password: string;
  name: string;
}

const registerUser = createAsyncThunk(
  'user/registerUser',
  async (
    { name, email, password }: IRegisterUser,
    { extra, rejectWithValue, dispatch },
  ) => {
    const { auth } = extra as IExtraArgument;
    if (name && email && password) {
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      dispatch(userSlice.actions.registerUserState({ register: true }));
      try {
        await auth().createUserWithEmailAndPassword(email, password);
        const res = await dispatch(createUser({ name, email }));
        unwrapResult(res);
      } catch (error) {
        dispatch(errorActions.setError(error));
        return rejectWithValue('error');
      }
    }
  },
);

interface ILoginUser {
  email: string;
  password: string;
}

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (
    { email, password }: ILoginUser,
    { extra, rejectWithValue, dispatch },
  ) => {
    const { auth } = extra as IExtraArgument;
    if (email && password) {
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      dispatch(userSlice.actions.registerUserState({ register: false }));
      try {
        await auth().signInWithEmailAndPassword(email, password);
      } catch (error) {
        dispatch(errorActions.setError(error));
        return rejectWithValue('error');
      }
    }
  },
);
const signInWithApple = createAsyncThunk(
  'user/signInWithApple',
  async (_, { extra, dispatch, rejectWithValue }) => {
    const { auth, Sentry, appleAuth } = extra as IExtraArgument;
    try {
      dispatch(userSlice.actions.registerUserState({ register: true }));
      dispatch(loaderActions.setGlobalLoaderState({ loader: true }));
      dispatch(
        appActions.setDisableBackgroundModeState({
          disableBackgroundMode: true,
        }),
      );
      const appleAuthRequestResponse = await appleAuth.performRequest({
        requestedOperation: appleAuth.Operation.LOGIN,
        requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
      });

      const { identityToken, nonce } = appleAuthRequestResponse;

      const providerUser = await auth().signInWithCredential(
        auth.AppleAuthProvider.credential(identityToken, nonce),
      );

      if (providerUser?.additionalUserInfo?.isNewUser) {
        const res = await dispatch(createUser({ providerUser }));
        unwrapResult(res);
      } else {
        dispatch(userSlice.actions.registerUserState({ register: false }));
      }
    } catch (error) {
      dispatch(loaderActions.setGlobalLoaderState({ loader: false }));
      dispatch(
        appActions.setDisableBackgroundModeState({
          disableBackgroundMode: false,
        }),
      );
      console.error(error);
      Sentry.captureException(error);
      if (error.type === 'FetchError') {
        dispatch(errorActions.setError(error));
      }
      return rejectWithValue('error');
    }
  },
);

const signInWithGoogle = createAsyncThunk(
  'user/signInWithGoogle',
  async (_, { extra, dispatch, rejectWithValue }) => {
    const { auth, Sentry, GoogleSignin } = extra as IExtraArgument;
    try {
      dispatch(userSlice.actions.registerUserState({ register: true }));
      dispatch(
        appActions.setDisableBackgroundModeState({
          disableBackgroundMode: true,
        }),
      );
      dispatch(loaderActions.setGlobalLoaderState({ loader: true }));

      GoogleSignin.configure();

      await GoogleSignin.signIn();

      const data = await GoogleSignin.getTokens();

      const providerUser = await auth().signInWithCredential(
        auth.GoogleAuthProvider.credential(data.idToken, data.accessToken),
      );

      if (providerUser?.additionalUserInfo?.isNewUser) {
        const res = await dispatch(createUser({ providerUser }));
        unwrapResult(res);
      } else {
        dispatch(userSlice.actions.registerUserState({ register: false }));
      }
    } catch (error) {
      dispatch(loaderActions.setGlobalLoaderState({ loader: false }));
      dispatch(
        appActions.setDisableBackgroundModeState({
          disableBackgroundMode: false,
        }),
      );
      console.error(error);
      Sentry.captureException(error);
      if (error.type === 'FetchError') {
        dispatch(errorActions.setError(error));
      }
      return rejectWithValue('error');
    }
  },
);

const removeGroup = createAsyncThunk(
  'user/removeGroup',
  async (gid: number, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      await fetchHandler(
        `${appSelectors.getUrl()}/users/${userSelectors.getId(state)}/group`,
        {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'DELETE',
          body: JSON.stringify({
            gid,
          }),
        },
      );
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      dispatch(appActions.setCacheState({ key: 'group' }));
      return {
        gid,
      };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState(state, action) {
      state.user = action.payload.user;
    },
    loginUserState(state, action) {
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    logoutUserState(state) {
      state.user = null;
      state.isLoggedIn = false;
    },
    registerUserState(state, action) {
      state.register = action.payload.register;
    },
  },
  extraReducers: builder => {
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.isLoggedIn = true;
    });
    builder.addCase(
      notificationActions.saveMessageToken.fulfilled,
      (state, action) => {
        if (state?.user?.messageToken) {
          state.user.messageToken = action.payload.messageToken;
        }
      },
    );
    builder.addMatcher(setActiveGroup, (state, action) => {
      if (state?.user?.group.gid) {
        state.user.group.gid = action.payload.gid;
      }
    });
    builder.addMatcher(deleteGroup, (state, action) => {
      if (state?.user?.group.gids) {
        state.user.group.gids = state.user.group.gids.filter(
          gid => gid !== action.payload.gid,
        );
      }
    });
    builder.addMatcher(createOrJoinGroup, (state, action) => {
      if (state?.user?.group.gids && action.payload?.group) {
        state.user.group.gids = state.user.group.gids.concat(
          action.payload.group.gid,
        );
        state.user.group.gid = state.user.group.gid || action.payload.group.gid;
      }
    });
  },
});

export const actions = {
  ...userSlice.actions,
  deleteUser,
  getUser,
  logoutUser,
  saveUser,
  registerUser,
  checkUserAuthentication,
  resetUserAuthentication,
  signInWithApple,
  signInWithGoogle,
  refreshUserAuthentication,
  loginUser,
  removeGroup,
};

export default userSlice.reducer;
