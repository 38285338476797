import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IState } from './types';
import { fetchHandler, getHeaders } from '@football/api';
import { selectors as appSelectors, actions as appActions } from '../app/';
import { IExtraArgument, IRootState } from '@football/types/app';
import { selectors as userSelectors } from '../user/';
import { actions as errorActions } from '../error';
import { actions as loaderActions } from '../loader';
import { IContractTeam } from '@football/types';

const initialState: IState = {
  teams: [],
};

const listTeams = createAsyncThunk(
  'team/listTeams',
  async (gid: number, { extra, rejectWithValue, dispatch, getState }) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      const teams = await (<Promise<IContractTeam[]>>(
        fetchHandler(`${appSelectors.getUrl()}/teams?gid=${gid}`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state, 'team'),
              userSelectors.getToken(state),
            ),
          },
          method: 'GET',
        })
      ));
      return { teams };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return rejectWithValue('error');
    }
  },
);

interface ISaveTeam {
  gid: number;
  team: number;
  position: string;
  setPosition: (position: string) => void;
  navigate?: (route: string) => void;
}

const saveTeam = createAsyncThunk(
  'team/saveTeam',
  async (
    { gid, team, position, setPosition, navigate }: ISaveTeam,
    { extra, dispatch, getState },
  ) => {
    const { DeviceInfo, Platform } = extra as IExtraArgument;
    const state = getState() as IRootState;
    try {
      dispatch(loaderActions.setLocalLoaderState({ loader: true }));
      const teams = await (<Promise<IContractTeam[]>>(
        fetchHandler(`${appSelectors.getUrl()}/teams`, {
          headers: {
            ...getHeaders(
              DeviceInfo,
              Platform,
              appSelectors.getCacheKey(state),
              userSelectors.getToken(state),
            ),
          },
          method: 'POST',
          body: JSON.stringify({
            team,
            position,
            gid,
          }),
        })
      ));
      dispatch(appActions.setCacheState({ key: 'team' }));
      setPosition('');

      dispatch(appActions.setModalState({ modal: undefined }));
      dispatch(loaderActions.setLocalLoaderState({ loader: false }));
      navigate && navigate('TabNavigator');
      return { teams };
    } catch (error) {
      dispatch(errorActions.setError(error));
      return null;
    }
  },
);

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listTeams.fulfilled, (state, action) => {
      state.teams = action.payload.teams;
    });
    builder.addCase(saveTeam.fulfilled, (state, action) => {
      if (action.payload?.teams) {
        state.teams = action.payload.teams;
      }
    });
  },
});

export const actions = {
  ...teamSlice.actions,
  listTeams,
  saveTeam,
};

export default teamSlice.reducer;
